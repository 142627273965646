import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { navigate } from "@reach/router";
import convertFromHTML from "html-to-draftjs";
import { EditorState, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { setIsStartTest } from "../../store/reducers/domainAssessmentReducer"
import { patchData } from "../../hook/fetchData";
import { getEnv } from "@urecruits/api";
import { useParams } from "react-router-dom";
const {API_ASSESSMENT} =getEnv()

const InstructionDialog = ({data}) => {  
  const dispatch = useDispatch();
  const [editorState,setEditorState]=useState()
  const {jobId}=useParams()

useEffect(()=>{
  const html=data?.instruction
 if(html){
  const blocksFromHTML = convertFromHTML(html);
  const contentState = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  );
  const newEditorState = EditorState.createWithContent(contentState);
  setEditorState(newEditorState);

 }
},[data])

  return (
    <div className="assessment">
      <div
        className="assessment__container"
        onClick={(e) => e.stopPropagation()}>
        <div className="assessment__footer">
          <h4>{data?.name} Instruction</h4>
        </div>
        <div className="assessment__instruction">
        <Editor
				editorState={editorState}
				wrapperClassName={`wrapper-class`}
				editorClassName="editor-class"
        toolbarHidden={true}
        readOnly={true} 
			/>
        </div>
        <div className="assessment__footer">
          <button
            className="assessment__footer__btn"
            onClick={async() => {
              dispatch(setIsStartTest(true));
              const startTime=new Date().toUTCString()
              await patchData(`${API_ASSESSMENT}/api/test-manager?jobId=${jobId}&assessmentType=Domain Assessment&testStatus=In Progress&startTime=${startTime}`,{})
            }}>
            Start Test
          </button>
        </div>
      </div>
    </div>
  );
};

export default InstructionDialog;
