import { useEffect, useState } from "react";
import { getEnv } from "@urecruits/api";
import fetchData from "../../hook/fetchData";
const {API_RECRUITMENT} = getEnv()

function ProviderErrorModal({onCancel}) {
  const [connectionsOptions,setConnectionOptions] = useState([])

  useEffect(()=>{
    fetchData(`${API_RECRUITMENT}/api/integrations/connection-status?providers=gcal,mcal`).then(data=>setConnectionOptions(data.map(i=>({url:i?.connectUrl,key:i?.key,label:`${i?.key === 'gcal' ? 'Google Calendar' : i?.key === 'mcal' && 'Outlook Calendar'}`}))))
  },[])

  return (
    <div className="coding-dialog">
      <div
        className="coding-dialog__container"
        onClick={(e) => e.stopPropagation()}
      >
     
      
        <div className="coding-dialog__head">
          <h2 className="coding-dialog__head__title">Calendar is not Integrated!!</h2>
          <button
            className="coding-dialog__head__cross-icon"
            onClick={onCancel}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18"
                stroke="#C1C5CB"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
        <h3 className="coding-dialog__message">
        To schedule meetings with candidates, please integrate your calendar. Click the button below to integrate.
        </h3>
        <div style={{ marginLeft: "unset" }} className="coding-dialog__footer">
          <div
            className="coding-dialog__footer__container"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              margin:'auto',
              gap:'20px'
            }}
          >
            {!!connectionsOptions.length && connectionsOptions.map(connection=>{
              console.log(connection)
              return <a
              className="coding-dialog__footer__container__connect-button"
              href={connection.url}
              key={connection.key}
            >
             {connection.label}
            </a>
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProviderErrorModal;
