import {configureStore} from "@reduxjs/toolkit";
import companies from "./reducers/companiesReducer";
import companyJobs from "./reducers/companyJobsReducer";
import {TypedUseSelectorHook, useSelector} from "react-redux";
import candidateJobs from "./reducers/candidateJobsReducer";
import candiateOffers from './reducers/candidateOffersReducer'
import candidateScoreboard from './reducers/candidateScoreboardReducer'
import domainAssessment from "./reducers/domainAssessmentReducer";

export const store = configureStore({
	reducer: {
		companies: companies,
		jobs: companyJobs,
		candidate_jobs: candidateJobs,
		domain_assessment: domainAssessment,
		candidate_offers: candiateOffers,
		candidate_scoreboard: candidateScoreboard
	},
});

export type RootState = ReturnType<typeof store.getState>
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;