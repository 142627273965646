import type {PayloadAction} from "@reduxjs/toolkit";
// eslint-disable-next-line no-duplicate-imports
import {createSlice} from "@reduxjs/toolkit";
import {ITabsFilter} from "../../types/global/global";
import {ICompanyTable, ICompanyUser} from "../../types/redux/companies";
import {CompaniesTableTabsEnums} from "../../enums/CompaniesTableEnums";

export const defaultFiltersCompanies = {
	searchValue: "",
	sortBy: "id",
	sortType: "ASC",
};

const initialStateCompanies: ICompanyTable = {
	tableView: 'table',
	noUsersSearch: false,
	filters: defaultFiltersCompanies,
	companies: [],
	nameTab: {
		id: 1,
		displayName: CompaniesTableTabsEnums.COMPANY_NAME,
	},
	companiesTabsFilter: [
		{
			id: 2,
			displayName: CompaniesTableTabsEnums.COMPANY_LOCATION,
			active: true,
			dbName: "",
		},
		{
			id: 3,
			displayName: CompaniesTableTabsEnums.MAIN_INDUSTRY,
			active: true,
			dbName: "",
		},
		{
			id: 4,
			displayName: CompaniesTableTabsEnums.OPEN_JOBS,
			active: true,
			dbName: "jobCount",
		},
	],
	pagination: {
		currentPage: 1,
		limit: 10,
		totalCount: 0,
	},
};

export const companies = createSlice({
	name: "companies",
	initialState: initialStateCompanies,
	reducers: {
		setCurrentPageCompanies: (state, action: PayloadAction<number>) => {
			state.pagination.currentPage = action.payload;
		},
		setLimitCompanies: (state, action: PayloadAction<number>) => {
			state.pagination.limit = action.payload,
				state.pagination.currentPage = 1;
		},
		setSearchValueCompanies: (state, action: PayloadAction<string>) => {
			state.filters.searchValue = action.payload;
		},
		setOrderCompanies: (state, action: PayloadAction<Array<ITabsFilter>>) => {
			state.companiesTabsFilter = action.payload;
		},
		setTotalCountCompanies: (state, action: PayloadAction<number>) => {
			state.pagination.totalCount = action.payload;
		},
		setCompanies: (state, action: PayloadAction<Array<ICompanyUser>>) => {
			state.companies = action.payload;
		},
		setSortTypeFilterCompanies:(state,action:PayloadAction<string>)=>{
			state.filters.sortType=action.payload
		},
		setSortByFilterCompanies:(state,action:PayloadAction<string>)=>{
			state.filters.sortBy=action.payload
		},
		setNoCompaniesSearch: (state, action: PayloadAction<boolean>) => {
			state.noUsersSearch = action.payload;
		},
		setTableView: (state, action: PayloadAction<string>) => {
			state.tableView = action.payload;
		},
	},
});


export const {
	setNoCompaniesSearch,
	setCurrentPageCompanies,
	setLimitCompanies,
	setSearchValueCompanies,
	setOrderCompanies,
	setTotalCountCompanies,
	setCompanies,
	setSortTypeFilterCompanies,
	setSortByFilterCompanies,
	setTableView
} = companies.actions;

export default companies.reducer;