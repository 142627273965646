import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setSelectedQuestion } from "../../store/reducers/domainAssessmentReducer"
import { useSelector } from "react-redux";
const getQuestionInfo=store=> store?.domain_assessment

export const Pagination = () => {
  const [maxVisiblePages, setMaxVisiblePages] = useState(10);
  const {selectedQuestion, totalQuestion} = useSelector(getQuestionInfo)
  
  const pageNumbers = Array.from({ length: totalQuestion }, (_, index) => index + 1);
  const dispatch=useDispatch()
    useEffect(() => {
        function handleWindowResize() {
        const windowWidth = window.innerWidth;

        if (windowWidth <= 768 && windowWidth > 600) {
            setMaxVisiblePages(5);
        } else if (windowWidth <= 600 && windowWidth > 480) {
            setMaxVisiblePages(4);
        }else if (windowWidth <= 480 && windowWidth > 400) {
            setMaxVisiblePages(3);
        } else if (windowWidth <= 400) {
            setMaxVisiblePages(2);
        }else{
            setMaxVisiblePages(totalQuestion > 10 ? 9 : 10);
        }
        }
        window.addEventListener('resize', handleWindowResize);
        handleWindowResize();
        return () => {
        window.removeEventListener('resize', handleWindowResize);
        };
    }, []); 
    
  let pagesToDisplay = [];
  let showEllipsisStart = false;
  let showEllipsisEnd = false;

  if (totalQuestion <= maxVisiblePages) {
    pagesToDisplay = pageNumbers;
  } else {
    const halfMaxVisible = Math.floor(maxVisiblePages / 2);

    if (selectedQuestion <= halfMaxVisible) {
      pagesToDisplay = pageNumbers.slice(0, maxVisiblePages);
      showEllipsisEnd = true;
    } else if (selectedQuestion >= totalQuestion - halfMaxVisible) {
      pagesToDisplay = pageNumbers.slice(-maxVisiblePages);
      showEllipsisStart = true;
    } else {
      pagesToDisplay = pageNumbers.slice(selectedQuestion - halfMaxVisible - 1, selectedQuestion + halfMaxVisible);
    //   showEllipsisStart = true;
      showEllipsisEnd = true;
    }
  }

  return (
    <div className="pagination">
      {showEllipsisStart && (
        <button className="pagination__button" onClick={() =>{  dispatch(setSelectedQuestion(1))
          dispatch(setSelectedQuestion(1));}}>
          1
        </button>
      )}
      
      {showEllipsisStart && (
        <span className="pagination__ellipsis">...</span>
      )}
      {pagesToDisplay.map((pageNumber) => (
        <button
          key={pageNumber}
          className={`pagination__button ${selectedQuestion === pageNumber ? "active" : ""}`}
          onClick={() => {
            dispatch(setSelectedQuestion(pageNumber))
            dispatch(setSelectedQuestion(pageNumber));
          }}
        >
          {pageNumber}
        </button>
      ))}
      {showEllipsisEnd && (
        <span className="pagination__ellipsis">...</span>
      )}
      {showEllipsisEnd && (
        <button className="pagination__button" onClick={() =>{ dispatch(setSelectedQuestion(totalQuestion))
          dispatch(setSelectedQuestion(totalQuestion))}}>
          {totalQuestion}
        </button>
      )}
    </div>
  );
};

