import {getEnv} from "@urecruits/api";
import axios from "axios";
import fetchData from "./fetchData";

const {API_RECRUITMENT} = getEnv();

export const selectSearchFuncCompanies = async (searchValue: string) => {
	return await axios(`${API_RECRUITMENT}/api/company/for-job/`, {
		params: {
			search: searchValue,
		},
	}).then((response) => response.data.rows.map((item: { name: string, tenantId: string, id: number }) => {
		return {
			label: item.name,
			value: item.tenantId,
			id: item.id,
		};
	}));
};

export const selectSearchFuncIndustry = async (searchValue: any) => {
	return await fetchData(`${API_RECRUITMENT}/api/industry/label/${searchValue}`)
		.then((response) => response.json());
};
