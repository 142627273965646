import { Link } from "react-router-dom";
import { store, useTypedSelector } from "../../../store";
import { changeApplyPopupCandidateJobs } from "../../../store/reducers/candidateJobsReducer";


const ApplyPopupCandidateJobs = ({ onApplyJob }) => {
	const { applyPopup } = useTypedSelector(state => state.candidate_jobs);

	const onClosePopup = () => {
		store.dispatch(changeApplyPopupCandidateJobs({
			visible: false,
			jobId: 0,
			jobTitle: "",
		}));
	};

	return (
		<div className="popup apply-popup">
			<div className="popup__step">
				<div className="popup__head">
					<p className="popup__head__headline">
						Apply to the job
					</p>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
						className="popup__head__close"
						onClick={onClosePopup}
					>
						<path d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18" stroke="#C1C5CB" strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round" />
					</svg>
				</div>
				<div className="popup__body">
					<p className="popup__body__text">
						Do you really want to apply for <strong>{applyPopup.jobTitle}</strong>? You can check your profile by link bellow, if you want
						to
						change some information
					</p>
					<Link to={"/profile-setting"} className="popup__body__link">
						Check profile
					</Link>
				</div>
				<div className="popup__bottom end">
					<button
						className="popup__bottom__cancel button--empty"
						onClick={onClosePopup}
					>
						Cancel
					</button>
					<Link
						className="popup__bottom__button button--filled"
						to={`/job/${applyPopup.jobId}/apply`}
						onClick={onApplyJob}
					>
						Submit
					</Link>
				</div>
			</div>
		</div>
	);
};

export default ApplyPopupCandidateJobs;