import { Link } from "react-router-dom";
import checkmarkIc from '../../image/icon/check-mark_blue_ic.svg';
import bookmarkIc from '../../image/icon/bookmark_blue_ic.svg';
import tempUser from '../../image/temp-user.png';
import { memo, useEffect, useState } from "react";
import fetchData from "../../hook/fetchData";
import { getEnv } from "@urecruits/api";
import { Skeleton, Stack } from "@mui/material";
import { DashboardEmptyDataComponent, DashboardListArrowSVG } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import SingleMobileItem from "@ucrecruits/globalstyle/components/Table/MobileTable/SingleMobileItem";

const {API_RECRUITMENT} = getEnv()

const columns = [
	{label:"Job Title",className:""},
	{label:"Posted On",className:""},
	{label:"Status",className:"small"},
	{label:"Candidates Overviews",className:"large"},
]
const PostedJobTable = ({styles}) => {
	const [data,setData] = useState([])
	const [isLoading,setIsLoading] = useState(true)
	const [isEmptyData,setIsEmptyData] = useState(false)

	const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const updateScreenSize = () => {
        setScreenWidth(window.innerWidth);
        };
        addEventListener("resize", updateScreenSize);
        return () => {
        removeEventListener("resize", updateScreenSize);
        };
    }, []);

	useEffect(()=>{
		async function getInfo(){
			try{
				await fetchData(`${API_RECRUITMENT}/api/company/jobs/?limit=10&offset=0&status=publish`).then(data=>{
					if(!data?.rows.length){
						setIsEmptyData(true)
					}else{
						setData(data?.rows)
					}
					setIsLoading(false)
				})
			}catch(error){
				console.log(error)
				setIsEmptyData(true)
				setIsLoading(false)
			}
		}
		getInfo().then()
	},[])


	return (
		<div className="dashboard-table recruiter" style={styles}>
			<div className="dashboard-table__header">
				<p className="dashboard-table__header__title">
					Posted jobs
				</p>
				<Link to={"/recruitment/jobs"} className="dashboard-table__header__link">View all jobs</Link>
			</div>
			{
				isLoading && <Stack height={"100%"} padding={"12px"}>
                    <Skeleton animation="wave" />
                </Stack>
			}{
				isEmptyData && <DashboardEmptyDataComponent />
			}
			{
				!isEmptyData && !isLoading && data.length>0 &&
				(
				screenWidth <=786 ? <>
				<div className="dashboard-table__table">
						<div className="dashboard-table__tbody">
							{data.map(item=><MobileItem item={item} key={item.id}/>)}
						</div>
					</div>
				</>:screenWidth >786 &&
				<table className="dashboard-table__table">
				<thead className="dashboard-table__thead">
					{
						columns.map((col,index)=>{
							return <td className={`dashboard-table__thead__td ${col.className || ""}`} key={index}>
								{col.label}
							</td>
						})
					}
				</thead>
				<tbody className="dashboard-table__tbody">
					{data.map(job=>{
						const date = new Date(job.createdAt).toString()
						const userData = job?.subscribes
						const appliedCount = userData?.length || 0;
						const savedCount = userData?.filter(sub=>sub.saveJob)?.length || 0;
						const userImgs = userData?.slice(0,3) || []

						return <tr className="dashboard-table__tbody__tr" key={job.id}>
						<td className="dashboard-table__tbody__td">
							<Link to={`/recruitment/job/${job.id}/matched-profiles`} className="dashboard-table__link">
								{job?.title||""}
							</Link>
						</td>
						<td className="dashboard-table__tbody__td gray">
						{date.substring(4,10)+", "+date.substring(11,15)|| ""}
						</td>
						<td className="dashboard-table__tbody__td small">
							<div className="dashboard-table__status publish">
								Published
							</div>
						</td>
						<td className="dashboard-table__tbody__td large">
							<div className="dashboard-table__info">
								<div className="dashboard-table__info__item">
									<img src={checkmarkIc} alt="checkmark ic"/>
									<span>{appliedCount}</span>
								</div>
								<div className="dashboard-table__info__item">
									<img src={bookmarkIc} alt="checkmark ic"/>
									<span>{savedCount}</span>
								</div>
							</div>
							<div className="dashboard-table__users">
								{
									userImgs.map(sub=>{
										return <div className="dashboard-table__users__item" key={sub.userId}>
											<img src={sub.user.avatar ? sub.user.avatar : tempUser} alt="temp user"/>
										</div>
									})
								}
								{ appliedCount>3 &&
									<div className="dashboard-table__users__count">
									<span>+{userData?.slice(3,userData?.length).length}</span>
								</div>}
							</div>
						</td>
					</tr>
					})
					}
				</tbody>
			</table>
				)
			}
		</div>
	);
};

export default memo(PostedJobTable);

const MobileItem =({item})=>{
    const [openItem,setOpenItem] = useState(false)
	const date = new Date(item.createdAt).toString()
	const userData = item?.subscribes
	const appliedCount = userData?.length || 0;
	const savedCount = userData?.filter(sub=>sub.saveJob)?.length || 0;


    return <div className="table-mobile__item dashboard-table__mobile__item">
    <div
      className={`table-mobile__top ${openItem ? "active" : ""}`}
      onClick={() => {
        setOpenItem((prev) => !prev);
      }}>
      <div className="table-mobile__top__left">
        <p className="table-mobile__top__name dashboard-table__mobile__text">
		<Link to={`/recruitment/job/${item.id}/matched-profiles`} className="dashboard-table__link">
			{item?.title||""}
		</Link>
		</p>
      </div>
      <DashboardListArrowSVG />
    </div>
    <div className={`table-mobile__item__body ${openItem ? "open" : ""}`}>
        <SingleMobileItem 
			title={columns[0].label} 
			content={<Link to={`/recruitment/job/${item.id}/matched-profiles`} className="table-mobile__item__text green">{item.title || ""}</Link>} 
			textStyle="table-mobile__item__text"
		/>
        <SingleMobileItem title={columns[1].label} content={date.substring(4,10)+", "+date.substring(11,15)|| ""} textStyle="table-mobile__item__text"/>
        <SingleMobileItem 
            title={columns[2].label} 
            content={<div className="dashboard-table__status publish">
				{item?.status}
			</div>} 
            textStyle="table-mobile__item__text"
        />
		<SingleMobileItem 
            title={columns[3].label} 
            content={
				<>
				<div className="dashboard-table__info">
					<div className="dashboard-table__info__item">
						<img src={checkmarkIc} alt="checkmark ic"/>
						<span>{appliedCount}</span>
					</div>
					<div className="dashboard-table__info__item">
						<img src={bookmarkIc} alt="checkmark ic"/>
						<span>{savedCount}</span>
					</div>
				</div>
				<div className="dashboard-table__users">
					{
						userData?.slice(0,3).map(sub=>{
							return <div className="dashboard-table__users__item" key={sub.userId}>
								<img src={sub.user.avatar ? sub.user.avatar : tempUser} alt="temp user"/>
							</div>
						})
					}
					{appliedCount>3 && <div className="dashboard-table__users__count">
						<span>+{userData?.slice(3,userData?.length).length}</span>
					</div>}
				</div>
				</>
			} 
            textStyle="table-mobile__item__text"
        />
		
    </div>
  </div>
}

