// import '../styles/_dialog.scss';
import { useNavigate } from "react-router";


function Modal({data}) {
  
  const navigate=useNavigate()
  return (
    <div className="coding-dialog" onClick={() => navigate("/")}>
      <div
        className="coding-dialog__container"
        onClick={(e) => e.stopPropagation()}
      >
     
      
        <div className="coding-dialog__head">
          <h2 className="coding-dialog__head__title">Appointment Booked Successfully</h2>
          <button
            className="coding-dialog__head__cross-ico"
            onClick={() => {navigate("/")
            }}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18"
                stroke="#C1C5CB"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
        <h3 className="coding-dialog__message">
          You have successfully booked the appointment for <strong>{data}</strong> job
        </h3>
        <div style={{ marginLeft: "unset" }} className="coding-dialog__footer">
          <div
            className="coding-dialog__footer__container"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <button
              className="coding-dialog__footer__container__delete"
              onClick={() => navigate("/")}
            >
              Got it!
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
