import {useTypedSelector} from "../../../../store";
import MobileTableItem from "./MobileTableItem";
import {memo} from "react";

const getCompanies=state=> state.companies.companies

const MobileTable=()=>{
	const companies=useTypedSelector(getCompanies)

	return<div className='table-mobile'>
		{
			companies.map(item=>{
				return <MobileTableItem key={item.id} item={item}/>
			})
		}
	</div>
}
export default memo(MobileTable)