import {IAsyncCheckBoxList, ITabsFilter} from "../global/global";

export interface IDomainAssessment {
  jobId: number | null,
  assessmentId: number | null,
  candidateAnswer: [{
	questionId:number | null,
	name:any,
	type:any,
	answer:any,
	score:number
  }],
  totalScore:number,
  duration:any,
  totalQuestion:number | null,
  selectedQuestion:number,
  testData:any,
  allQuestionScore:number,
  isStartTest:boolean
  applyPopup:boolean,
	successApplyPopup: boolean,
  modal: any | null,
  modalClose:boolean,
}

export enum CodingModalsEnum {
  deadline = "deadline",
  inProgress = "inProgress",
  invite = "invite",
  congratulations = "congratulations",
  completed = "completed",
  settings = "settings",
  keyboardShortcuts = "keyboardShortcuts",
  listOfQuestions = "listOfQuestions",
}
