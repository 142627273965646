import { Link, useNavigate } from "react-router-dom";
import AppliedJobsTable from "../components/Dashboard/AppliedJobsTable";
import TotalCount from "../components/Dashboard/TotalCount";

import tempUser from "../image/temp-user.png";
import noData from "../image/icon/no-data.svg";
import { useEffect, useState } from "react";
import CalendarDayView from "../components/CalendarDayView/CalendarDayView";
import { getEnv } from "@urecruits/api";
import fetchData from "../hook/fetchData";
import JobMatches from "../components/Dashboard/JobMatches";
import RescheduleTask from '../components/Dashboard/RescheduleTasks';
import { Stack, Skeleton } from '@mui/material';
import { DashboardEmptyDataComponent } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const { API_RECRUITMENT } = getEnv()

// const myProfileViews = [
//   {
//     name: "01.11",
//     uv: 4000,
//   },
//   {
//     name: "02.11",
//     uv: 3000,
//   },
//   {
//     name: "03.11",
//     uv: 2000,
//   },
//   {
//     name: "04.11",
//     uv: 2780,
//   },
//   {
//     name: "05.11",
//     uv: 1890,
//   },
//   {
//     name: "06.11",
//     uv: 2390,
//   },
//   {
//     name: "07.11",
//     uv: 3490,
//   },
//   {
//     name: "08.11",
//     uv: 4000,
//   },
//   {
//     name: "09.11",
//     uv: 3000,
//   },
//   {
//     name: "10.11",
//     uv: 2000,
//   },
//   {
//     name: "11.11",
//     uv: 2780,
//   },
//   {
//     name: "12.11",
//     uv: 1890,
//   },
//   {
//     name: "13.11",
//     uv: 2390,
//   },
//   {
//     name: "14.11",
//     uv: 3490,
//   },
//   {
//     name: "15.11",
//     uv: 4000,
//   },
//   {
//     name: "16.11",
//     uv: 3000,
//   },
//   {
//     name: "17.11",
//     uv: 2000,
//   },
//   {
//     name: "18.11",
//     uv: 2780,
//   },
//   {
//     name: "19.11",
//     uv: 1890,
//   },
//   {
//     name: "20.11",
//     uv: 2390,
//   },
//   {
//     name: "21.11",
//     uv: 3490,
//   },
//   {
//     name: "22.11",
//     uv: 4000,
//   },
//   {
//     name: "23.11",
//     uv: 3000,
//   },
//   {
//     name: "24.11",
//     uv: 2000,
//   },
//   {
//     name: "25.11",
//     uv: 2780,
//   },
//   {
//     name: "26.11",
//     uv: 1890,
//   },
//   {
//     name: "27.11",
//     uv: 2390,
//   },
//   {
//     name: "28.11",
//     uv: 3490,
//   },
//   {
//     name: "29.11",
//     uv: 3490,
//   },
//   {
//     name: "30.11",
//     uv: 3490,
//   },
// ];

const CandidateDashboard = () => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [savedjobs, setSavedJobs] = useState(0)
  const [matchedJobsCount, setMatchedJobsCount] = useState(0)
  const [loading,setLoading] = useState(false)
  const [matchedJobs,setMatchedJobs] = useState([])
  useEffect(() => {
    setLoading(true)
    fetchData(`${API_RECRUITMENT}/api/job/match/jobs?limit=100&sortBy=match_percentage&sortType=DESC`).then(res => {
      setMatchedJobs(res.rows);
      setLoading(false);
    }).catch((e) => {
      console.log("error Getting matched Jobs", e)
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    fetchData(`${API_RECRUITMENT}/api/candidate`).then(
      (dd)=>{
        const fullName=`${dd?.user?.firstname} ${dd?.user?.lastname}`
        setData(fullName)
      }
    );
    fetchData(`${API_RECRUITMENT}/api/subscribe/jobs/saved/count`).then(data => setSavedJobs(data.todaySavedJobs || 0))
    fetchData(`${API_RECRUITMENT}/api/job/match/count`).then(data => setMatchedJobsCount(data.todayMatchedJobs || 0))
  }, []);
  useEffect(() => {
    const cookies = document.cookie.split("; ");
    if (cookies) {
      for (let i = 0; i < cookies.length; i++) {
        const [cookieName, cookieValue] = cookies[i].split("=");
        if (cookieName === "jobId") {
          navigate(`/job/${decodeURIComponent(cookieValue)}`);
        }
      }
    }
  }, [document.cookie]);

  return (
    <section className="dashboard">
      <h2 className="dashboard__headline">Hello, {data && data}</h2>
      <div className="dashboard__inner candidate">
        <div className="dashboard__top">
          <JobMatches matchedJobs={matchedJobs} loading={loading}/>
          <div className="dashboard__top__middle">
            <TotalCount
              link="/candidate/jobs?type=match"
              title={"matching jobs"}
              value={matchedJobsCount}
              label={"for today"}
              position={"left"}
            />
            <TotalCount
              link="/candidate/jobs?type=save"
              title={"your saved jobs"}
              value={savedjobs}
              label={"for today"}
              position={"right"}
            />
            {/* <AreaChart title={"My profile views"} data={myProfileViews} /> */}
            <AppliedJobsTable styles={{ width: "100%"}} />
          </div>
          <CalendarDayView />
          {/* <CandidateCalendar/> */}
        </div>
        <div className="dashboard__bottom">
          <RescheduleTask />
          <TopCompanies matchedJobs={matchedJobs}/>
        </div>
      </div>
    </section>
  );
};

export default CandidateDashboard;



const TopCompanies = ({matchedJobs}) => {
  const [companyList,setCompanyList] = useState([])
  const [loading,setLoading] = useState(true)
  const [isEmpty,setIsEmpty] = useState(false)
  const navigate = useNavigate()

  useEffect(()=>{
      if(!!matchedJobs.length){
        transformCompanyList(matchedJobs)
        .then(d=>{
          setCompanyList(d);
          setLoading(false)
          setIsEmpty(false)
        })
        .catch(err=>{
          setLoading(false)
          setIsEmpty(true)
        })
      }else{
        setIsEmpty(true)
      }
  },[matchedJobs])
  return (
    <div className="top-companies">
      <div className="top-companies__head">
        <p className="top-companies__head__headline">Top companies</p>
        <Link to="/candidate/companies" className="top-companies__head__link">
          View all
        </Link>
      </div>
      <ul className="top-companies__list">
        {
          loading && <Stack height={"100%"}>
            <Skeleton animation="wave" />
          </Stack>
        }
        {
          isEmpty&& !loading && <DashboardEmptyDataComponent />
        }
        {
          !loading && !isEmpty && !!companyList.length && 
          companyList.map(company=>{
            return <li className="top-companies__item" key={company.id} onClick={() => navigate(`/candidate/company/${company.tenantId}`)}>
              <div className="top-companies__item__left">
                <p className="top-companies__item__name">{company.name}</p>
                <p className="top-companies__item__members">
                  Total Member <span>{company.members?.count || 0}</span>
                </p>
              </div>
              <div className="top-companies__item__right">
                <ul className="top-companies__users">
                  {
                    !!company.members?.count && 
                    company.members.avatars?.slice(0,3).map((img,index)=>{
                      return <li className="top-companies__users__item" key={index}>
                        <img
                          src={img || tempUser}
                          alt=""
                          className="top-companies__users__img"
                        />
                      </li>
                    })
                  }
                  {!!company.members?.count && company.members?.count>3  && <li className="top-companies__users__count">
                    <span>{"+"+(company.members?.count - 3) || 0}</span>
                  </li>}
                </ul>
              </div>
            </li>
          })
        }
      </ul>
    </div>
  );
};

const transformCompanyList = async (array) => {
  const transformArray = [];
  const distinctCompanies = Array.from(new Set(array.map(item => item.companyId)));

  const companyIdQuery = distinctCompanies.length > 0
    ? distinctCompanies.map(id => `&companyId=${id}`).join('')
    : '';

  const companyCountMap = distinctCompanies.reduce((acc, companyId:number) => {
    acc[companyId] = array.filter(item => item.companyId === companyId).length;
    return acc;
  }, {});

  const data = await fetchData(`${API_RECRUITMENT}/api/company/all/members?limit=10&offset=0&sortBy=id&sortType=DESC${companyIdQuery}`);

  if (data.count) {
    const companyMap = new Map(array.map(item => [item.companyId, item.company]));

    const membersMap = distinctCompanies.reduce((acc, companyId:number) => {
      acc[companyId] = { count: 0, avatars: [] };
      return acc;
    }, {});

    for (let member of data.rows) {
      if (membersMap[member.companyId]) {
        membersMap[member.companyId].count += 1;
        membersMap[member.companyId].avatars.push(member.user?.avatar);
      }
    }

    distinctCompanies.forEach((companyId:any) => {
      const companyDetails:any = companyMap.get(companyId);
      if (companyDetails) {
        transformArray.push({
          id: companyId,
          members: membersMap[companyId],
          name: companyDetails.name,
          tenantId: companyDetails.tenantId,
          occurrenceCount: companyCountMap[companyId]
        });
      }
    });
    transformArray.sort((a, b) => b.occurrenceCount - a.occurrenceCount)
  }

  return transformArray;
};


const CandidateCalendar = () => {
  return (
    <div className="candidate-calendar">
      <p className="candidate-calendar__headline">Today, 02 November</p>
      <div className="candidate-calendar__inner">
        <img src={noData} alt="" className="candidate-calendar__empty" />
      </div>
    </div>
  );
};
