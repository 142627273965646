import {Link, useParams} from "react-router-dom";
import {memo} from "react";
import defaultAvatar from "../../image/icon/avatar.svg";
import {numberWithCommas} from "../../hook/numberWithCommas";


const HotJobs = ({jobs, companyLogo}) => {
	const params = useParams();

	return (
		<div className="hot-jobs">
			<p className="hot-jobs__headline">Hot jobs of this company</p>
			<div className="hot-jobs__list">
				{
					jobs?.map((item, index) => {
						return (
							<div className="hot-jobs__item" key={index}>
								<div className="hot-jobs__head">
									<div className="hot-jobs__left">
										<div className="hot-jobs__logo">
											<img src={companyLogo || defaultAvatar} alt="logo" className="hot-jobs__logo__img"/>
										</div>
									</div>
									<div className="hot-jobs__right">
										<Link to={`/job/${item.id}`}className="hot-jobs__title">
											<span>{item.title}	</span>
										</Link>
										<ul className="hot-jobs__info">
											{
												item?.locations[0]?.city&&<li className="hot-jobs__info__item">
												<span>
														{item?.locations[0]?.city&&`${item?.locations[0].city || ""}${item?.locations[0] ? "," : ""} ${item?.locations[0].state || ""}`}
												</span>
												</li>
											}
											<li className="hot-jobs__info__item">
												{item.experienceMin}-{item.experienceMax} years
											</li>
											<li className="hot-jobs__info__item">
												${numberWithCommas(item.salaryYearMin)} - ${numberWithCommas(item.salaryYearMax)} PA
											</li>
										</ul>
									</div>
								</div>
								<div className="hot-jobs__tags">
									{
										item.skills.map((item, index) => {
											return (
												<div className="hot-jobs__tags__item" key={index}>
													{item}
												</div>
											);
										})
									}
								</div>
							</div>
						);
					})
				}
			</div>
			<Link to={`/candidate/company/${params.tenantId}/opened-jobs`} className="hot-jobs__link">See all jobs</Link>
		</div>
	);
};

export default memo(HotJobs);