import LineChart from "../components/Dashboard/LineChart";
import CircleChart from "../components/Dashboard/CircleChart";
import AreaChart from "../components/Dashboard/AreaChart";
import PostedJobTable from "../components/Dashboard/PostedJobTable";
import CalendarDayView from "../components/CalendarDayView/CalendarDayView";
import EmployeesList from "../components/Dashboard/Employess";
import { useEffect, useState } from "react";
import fetchData from "../hook/fetchData";
import { getEnv } from "@urecruits/api";
import { CandidateJobsStatus } from "../enums/CandidateJobsEnums";
import { AuthGuard, useHasPermission } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
const { API_RECRUITMENT } = getEnv()

const jobsSummaryData = {
  totalJobsCount: 243,
  summary: [
    {
      value: 66,
      name: "Publish",
    },
    {
      value: 35,
      name: "On hold",
    },
    {
      value: 12,
      name: "Closed",
    },
    {
      value: 5,
      name: "Pending",
    },
    {
      value: 12,
      name: "Draft",
    },
  ],
};

const pendingJobsData = [
  {
    name: "01.11",
    uv: 4000,
  },
  {
    name: "02.11",
    uv: 3000,
  },
  {
    name: "03.11",
    uv: 2000,
  },
  {
    name: "04.11",
    uv: 2780,
  },
  {
    name: "05.11",
    uv: 1890,
  },
  {
    name: "06.11",
    uv: 2390,
  },
  {
    name: "07.11",
    uv: 3490,
  },
  {
    name: "08.11",
    uv: 4000,
  },
  {
    name: "09.11",
    uv: 3000,
  },
  {
    name: "10.11",
    uv: 2000,
  },
  {
    name: "11.11",
    uv: 2780,
  },
  {
    name: "12.11",
    uv: 1890,
  },
  {
    name: "13.11",
    uv: 2390,
  },
  {
    name: "14.11",
    uv: 3490,
  },
  {
    name: "15.11",
    uv: 4000,
  },
  {
    name: "16.11",
    uv: 3000,
  },
  {
    name: "17.11",
    uv: 2000,
  },
  {
    name: "18.11",
    uv: 2780,
  },
  {
    name: "19.11",
    uv: 1890,
  },
  {
    name: "20.11",
    uv: 2390,
  },
  {
    name: "21.11",
    uv: 3490,
  },
  {
    name: "22.11",
    uv: 4000,
  },
  {
    name: "23.11",
    uv: 3000,
  },
  {
    name: "24.11",
    uv: 2000,
  },
  {
    name: "25.11",
    uv: 2780,
  },
  {
    name: "26.11",
    uv: 1890,
  },
  {
    name: "27.11",
    uv: 2390,
  },
  {
    name: "28.11",
    uv: 3490,
  },
  {
    name: "29.11",
    uv: 3490,
  },
  {
    name: "30.11",
    uv: 3490,
  },
];


const getLast7DaysDatePairs = () => {
  const today = new Date();
  const datePairs = [];

  for (let i = 0; i < 7; i++) {
    const dateFrom = new Date(today);
    dateFrom.setDate(today.getDate() - i);
    const dateTo = new Date(today);
    dateTo.setDate(today.getDate() - i - 1);
    datePairs.push({ dateFrom, dateTo });
  }

  return datePairs;
};

async function getEmployeeCount() {
  const array = []
  const dates = getLast7DaysDatePairs()
  for (let i = dates.length - 1; i >= 0; i--) {
    await fetchData(`${API_RECRUITMENT}/api/company/members?dateJoiningFrom=${dates[i].dateTo}&dateJoiningTo=${dates[i].dateFrom}`)
      .then(emp => {
        array.push({ value: emp.count, name: `Day ${7 - i}` })
      })
  }
  return array
}

async function getJobsCount() {
  const array = []
  const dates = getLast7DaysDatePairs()
  for (let i = dates.length - 1; i >= 0; i--) {
    await fetchData(`${API_RECRUITMENT}/api/company/jobs?dateFrom=${dates[i].dateTo}&dateTo=${dates[i].dateFrom}&status=publish`)
      .then(emp => {
        array.push({ value: emp.count, name: `Day ${7 - i}` })
      })
  }
  return array
}

async function getPendingJobs() {
  const array = []
  const dates = getLast7DaysDatePairs()
  for (let i = dates.length - 1; i >= 0; i--) {
    await fetchData(`${API_RECRUITMENT}/api/company/jobs?dateFrom=${dates[i].dateTo}&dateTo=${dates[i].dateFrom}&status=approval`)
      .then(emp => {
        array.push({ uv: emp.count, name: `${7 - i}` })
      })
  }
  return array
}

async function getJobSummary() {
  const array = []
  await fetchData(`${API_RECRUITMENT}/api/company/jobs`)
    .then(data => {
      const jobs = data?.rows
      const publish = jobs?.filter(job => job.status === CandidateJobsStatus.PUBLISH).length || 0;
      const onhold = jobs?.filter(job => job.status === CandidateJobsStatus.ONHOLD).length || 0;
      const draft = jobs?.filter(job => job.status === CandidateJobsStatus.DRAFT).length || 0;
      const approval = jobs?.filter(job => job.status === CandidateJobsStatus.APPROVAL).length || 0;
      const rejected = jobs?.filter(job => job.status === CandidateJobsStatus.REJECTED).length || 0;
      const closed = jobs?.filter(job => job.status === CandidateJobsStatus.CLOSED).length || 0;

      array.push({
        totalJobsCount: data?.count || 0,
        summary: [
          { name: "Active", value: publish },
          { name: "On hold", value: onhold },
          { name: "Closed", value: closed },
          { name: "Pending", value: approval },
          { name: "Draft", value: draft }
        ]
      })
    })
  return array[0]
}

const AdminDashboard = () => {
  const [employeeCount, setEmployeeCount] = useState([]);
  const [jobsCounts, setJobsCount] = useState([]);
  const [pendingJobs, setPendingJobs] = useState([]);
  const [jobSummary, setJobSummary] = useState({ totalJobsCount: 0, summary: [] });
  const {checkUserPermission} = useHasPermission();
  
  useEffect(() => {
    async function getInfo() {
      try {
        checkUserPermission('team-members', 'view') && getEmployeeCount().then(data => setEmployeeCount(data))
        if(checkUserPermission('job-post', 'view')) {
          getJobsCount().then(data => setJobsCount(data))
          getJobSummary().then(data => setJobSummary(data))
          getPendingJobs().then(data => setPendingJobs(data))}
      } catch (error) {
        console.log(error)
      }
    }
    getInfo()
  }, [checkUserPermission('team-members', 'view'),checkUserPermission('job-post', 'view')])

  return (
    <section className="dashboard">
      <h2 className="dashboard__headline">Home</h2>
      <div className="dashboard__inner admin">

        <div className="dashboard__left">
          <div className="line-chart-container">
            <AuthGuard module='team-members' permission='view' >
              <LineChart
                data={employeeCount || []}
                name={"New employees"}
                position={"left"}
              />
            </AuthGuard>
            <AuthGuard module='job-post' permission='view' >
              <LineChart
                data={jobsCounts || []}
                name={"Posted Jobs"}
                position={"right"}
              />
            </AuthGuard>
          </div>
          <AuthGuard module='job-post' permission='view' >
            <CircleChart data={jobSummary} />
            <AreaChart title={"Pending jobs"} data={pendingJobs} />
            <PostedJobTable styles={{}} />
          </AuthGuard>
        </div>
        <div className="dashboard__right">
          {/* <DashboardDropdown /> */}
          <AuthGuard module='job-post' permission='view' >
            <CalendarDayView view="admin" />
          </AuthGuard>
          <AuthGuard module='team-members' permission='view' >
            <EmployeesList />
          </AuthGuard>
        </div>
      </div>
    </section>
  );
};

export default AdminDashboard;


