import {memo, useEffect, useState} from 'react'
import {store, useTypedSelector} from "../../../store";
import {setSkillsFilterCompanyJobs} from "../../../store/reducers/companyJobsReducer";

const filterSkillFunc=state=>state.jobs.filters.skills

const MultiSelectListItem = ({ item,setInputValue }) => {
  const [active, setActive] = useState(false)
  const selectedSkills=useTypedSelector(filterSkillFunc)

  const onClickHandler = () => {
    if (!(selectedSkills.find(x => x === item.name))) {
      setActive(!active)
      store.dispatch(setSkillsFilterCompanyJobs([...selectedSkills,item.name]))
    } else {
      setActive(false)
      store.dispatch(setSkillsFilterCompanyJobs(selectedSkills.filter(x => x !== item.name)))
    }
    setInputValue('')
  }

  useEffect(() => {
    if (selectedSkills.find(x => x === item.name)) {
      setActive(true)
    } else {
      setActive(false)
    }
  }, [selectedSkills])

  return (
    <div
      className={`multi-select__list__item ${active ? 'active' : ''}`}
      onClick={onClickHandler}
      role="button"
      tabIndex={0}
    >
      {item.name}
    </div>
  )
}

export default memo(MultiSelectListItem)