import applyIc from "../../../image/icon/apply_ic.svg";
import clockIc from "../../../image/icon/clock_ic.svg";
import { Fragment, memo, useState } from "react";

const TableListItem = ({ row, columns, actions }) => {
	const dayPublishConvert = (publishDay: string): number => {
		return Math.round((Date.now() - new Date(publishDay).valueOf()) / 86400000);
	};
	return (
		<Fragment key={row.id}>
			<div className="job-list__head">
				<p className="job-list__head__headline">
					{row.title}
				</p>
				{
					actions ? (<div className="job-list__action">
						{
							actions.map((action, index) => {

								const actionObj = action.getAction ? action.getAction(row) : action;
								if (!actionObj) return;
								const { svg, title, icon, onClick: onActionClick, className } = actionObj

								return (<div
									className={`job-list__action__item ${className}`}
									onClick={(event) => {
										onActionClick(row, event)
									}}
									key={title}
								>
									{svg ?? <img src={icon} alt={title} />}
									{title}
								</div>)
							})
						}
					</div>) : ''
				}

			</div>
			<ul className="job-list__info">
				{!!row.locations.length && row.locations[0].city && row.locations[0].state ? <li className="job-list__info__item">
					<span>{`${row.locations[0].city}, ${row.locations[0].city}`}</span>
				</li> : ''}
				<li className="job-list__info__item">
					{`${row.experienceMin}-${row.experienceMax} years`}
				</li>
				<li className="job-list__info__item">
					{`$${row.salaryMonthMin} - $${row.salaryMonthMax} PA`}
				</li>
				<li className="job-list__info__item">
					<img src={clockIc} alt="clock ic" />
					{dayPublishConvert(row.createdAt) === 0 ? "today" : dayPublishConvert(row.createdAt) + " days ago"}
				</li>
			</ul>
			<ul className="job-list__tags">
				{
					row.skills.map((skill, index) => {
						return (
							<li className="job-list__tags__item" key={index}>
								{skill}
							</li>
						);
					})
				}
			</ul>
			<p className="job-list__about">{row.shortDescription}</p>
		</Fragment>
	);
};

export default TableListItem;