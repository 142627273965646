import { getEnv } from "@urecruits/api";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CountdownTimer from "../components/candidate-assessment/CountdownTimer";
import InstructionDialog from "../components/candidate-assessment/InstructionDialog";
import { Pagination } from "../components/candidate-assessment/Pagination";
import { Question } from "../components/candidate-assessment/question";
import fetchData, { patchData, postData } from "../hook/fetchData";
import * as types from "../types/redux/domainAssessment"
const {API_ASSESSMENT}=getEnv()
const getData = store => store.domain_assessment;
import { useNavigate, useParams } from "react-router-dom";
import { useTypedSelector } from "../store";
import { setIsStartTest, setModal, setSelectedQuestion, setTestData, setTotalQuestions, setTotalScore } from "../store/reducers/domainAssessmentReducer";
import { CodingModalsEnum } from "../types/redux/domainAssessment";
import VideoMeetLoader from "../../recruitments/screen/VideoMeetLoader";

const getIsStartTest=store => store.domain_assessment?.isStartTest
const DomainAssessment = (props) => {
    const dispatch=useDispatch()
    const navigate=useNavigate()
    const isStartTest=useSelector(getIsStartTest)
    const[domainAssessmentData, setDomainAssessmentData]=useState(null)
    const {testData,selectedQuestion,totalQuestion,modal,candidateAnswer} = useTypedSelector(getData);
    const {jobId,assessmentId}=useParams()
    const [loading, setIsLoading]=useState(false)
    const [ isTestGiven, setIsTestGiven]=useState(false)

    const questionStyle = {
      color: selectedQuestion === 1 ? "#ccc" : undefined,
      border: selectedQuestion === 1 ? "1px solid #ccc" : undefined,
      cursor: selectedQuestion === 1 ? "not-allowed" : undefined,
    }
     
    if(testData?.balanceDuartion <= 0){
      dispatch
    }
    useEffect(() => {
      setIsLoading(true);
      fetchData(`${API_ASSESSMENT}/api/test-manager?jobId=${jobId}&assessmentType=Domain Assessment`).then((data)=>{        
        if(data){
          const currentDate = new Date();
          const deadline = parseInt(data?.deadline) * 60 * 60 * 1000
          const updatedTime= currentDate.getTime() + deadline;

          setIsLoading(false);
          if(data?.testStatus ==='In Progress' && !isStartTest){
            setIsTestGiven(true)
            dispatch(setModal(types.CodingModalsEnum.inProgress));
          }else if((data?.testStatus ==='Completed' || updatedTime < new Date().getTime()) && !isStartTest){
            setIsTestGiven(true)
            dispatch(setModal(types.CodingModalsEnum.deadline));
          }
        }
      })
    }, [assessmentId]);

    useEffect(() => {
      const handleLeaveButtonClick = async () => {
        const confirmed = window.confirm("Are you sure you want to leave?");
      };
      window.addEventListener("beforeunload", handleLeaveButtonClick);
    
      return () => {
        window.removeEventListener("beforeunload", handleLeaveButtonClick);
         patchData(`${API_ASSESSMENT}/api/test-manager?jobId=${jobId}&assessmentType=Domain Assessment&testStatus=Completed`, {});
      };
    }, []);
  

    const element = document.getElementsByTagName('html');
    useEffect(() => {
        if (element && element[0] && element[0].classList) {
            element[0].classList.remove('theme-dark')
        }
    }, [element])

    useEffect(()=>{
      setIsLoading(true)
        fetchData(`${API_ASSESSMENT}/api/domain-questions/candidate/${assessmentId}`)
        .then((res)=>{
            setDomainAssessmentData(res)
            dispatch(setTestData(res))
            dispatch(setTotalQuestions(res?.questions?.length))
            dispatch(setIsStartTest(false));
            setIsLoading(false)
        })
    },[])

    const handleSubmit = async () => {
      let totalScore = 0;
    
      const requestBody = {
        jobId: Number(jobId),
        assessmentId: Number(assessmentId),
        candidateAnswers: candidateAnswer,
        duration: testData?.duration,
        totalScore: 0,
      };
      const hasTextType = candidateAnswer?.some((res) => res?.type === "text");
      if (!hasTextType) {
        candidateAnswer?.forEach((res) => {
          const data = JSON?.parse(res?.answers);
    
          if (res?.type === "single" && data?.[0]?.isCorrect) {
            totalScore += res?.score || 0;
          } else if (res?.type === "multiple") {
            data?.forEach((item) => {
              totalScore += item?.score || 0;
            });
          }
        });
        requestBody.totalScore = totalScore;
      }else{
        requestBody.totalScore = null;
      }
    
      await postData(`${API_ASSESSMENT}/api/domain-result`, requestBody);
      if(!hasTextType){
        dispatch(setModal(CodingModalsEnum.completed))
        dispatch(setTotalScore(totalScore))
      }else{
        dispatch(setModal(CodingModalsEnum.congratulations));
      }
    };
    
    return (
      <>
       {isStartTest && 
        <div className="domain-assessment">
          <div className="domain-assessment__timer"><CountdownTimer submitExecuter= {handleSubmit}/></div>
          <div className="domain-assessment__question">
            <Question />
          </div>
          <div className="domain-assessment__pagination">
            <Pagination/>
          </div>
          <div className="domain-assessment__footer">
          <div>
          <button
            className="domain-assessment__footer__cancel_btn"
            onClick={() => {
              navigate("/")
            }}>
            Cancel
          </button>
          </div>
          <div className="domain-assessment__footer__sub">
          <button
            className="domain-assessment__footer__prev_btn"
            onClick={() => {
              if(selectedQuestion !== 1){
                dispatch(setSelectedQuestion(selectedQuestion - 1))
              }
            }}
            style={questionStyle}            
            disabled={selectedQuestion === 1}>
            Previous
          </button>  <button
            className="domain-assessment__footer__next_btn"
            onClick={() => {            
              if(selectedQuestion!==totalQuestion){
              dispatch(setSelectedQuestion(selectedQuestion + 1))
              }else{
                handleSubmit()
              }
            }}>
          { selectedQuestion===totalQuestion ? "Submit" : "Next"}
          </button>
          </div>
          </div>
      </div>
       }
       {!isStartTest && !isTestGiven && (
          <>
            {loading && <VideoMeetLoader />}
            <InstructionDialog data={domainAssessmentData} />
          </>
        )}
      </>
    );
};

export default DomainAssessment;