const DomainResultModal = ({ type, children, onSubmit:{buttonText,handler},onClose }) => {
  const popupActionLabel = type === "Completed" ? "Passed" : type === "Rejected" ? "Failed" : "";

  return (
    <div className="popup apply-popup">
      <div className="popup__step">
        <div className="popup__head">
          <p className="popup__head__headline">
            {popupActionLabel} Assessment
          </p>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="popup__head__close"
            onClick={()=>onClose()}
          >
            <path d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18" stroke="#C1C5CB" strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round" />
          </svg>
        </div>
        <div className="popup__body">
          <p className="popup__body__text">
            {
              type === 'Completed' ?
              <>
              <strong>
              Congratulations! 
              </strong>
              You have successfully completed the assessment.
              </>
              : type === 'Rejected' ?
              <>
              We are sorry, your scores are not meet our expectations. Thanks for giving your valuable time. 
              </> 
              :""
            }
          </p>
          <p>
            {children}
          </p>
          <p className="popup__body__bold">Regards, uRecruits Team</p>
        </div>
        <div className="popup__bottom center">
          <button
            className="popup__bottom__button button--filled"
            onClick={handler}
          >
            {buttonText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DomainResultModal;