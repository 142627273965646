import { useEffect } from "react";

export const useModal = (isOpen) => {
  useEffect((): (() => void) => {
    // @ts-ignore
    if (isOpen) document.body.classList.add("body--overflow-hidden");
    else document.body.classList.remove("body--overflow-hidden");

    return () => document.body.classList.remove("body--overflow-hidden");
    // eslint-disable-next-line
  }, [isOpen]);
};

export default useModal;
