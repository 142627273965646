import arrowIc from '../../image/icon/top-left-arrow_ic.svg';
import { Link } from "react-router-dom";

const TotalCount = ({title, value, label, position,link=''}) => {

	return(
		<Link  to={link || '/'} className={`total-count ${position}`}>
			<div className="total-count__head">
				<p className="total-count__head__headline">{title}</p>
				<img src={arrowIc} alt="arrow ic" className="total-count__head__icon"/>
			</div>
			<p className="total-count__value">{value}<span>{label}</span></p>
		</Link>
	)
}

export default TotalCount