export enum CandidateJobsTableTabsEnums {
	JOB_TITLE = "Job title",
	COMPANY_NAME = "Company",
	JOB_LOCATION = "Job Location",
	EXPERIENCE = "Experience",
	SALARY_RANGE = "Salary range",
	MAIN_SKILLS = "Main Skills",
	POSTED_ON = "Posted on",
	EDUCATION = "Education",
	JOB_TYPE = "Job type",
	PREFERABLE_SHIFT = "Preferable shift",
	INDUSTRY_TYPE = "Industry type",
	FUNCTIONAL_AREA = "Functional area",
	ACTIONS = "Actions"
}
export enum CandidateJobsStatus{
	PUBLISH="publish",
	ONHOLD="onhold",
	APPROVAL="approval",
	DRAFT="draft",
	REJECTED="rejected",
	CLOSED="closed"
}