export default () => {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M18.3337 11V7.50114C18.3337 6.58066 17.5875 5.83447 16.667 5.83447H3.33366C2.41318 5.83447 1.66699 6.58066 1.66699 7.50114V15.8345C1.66699 16.7549 2.41318 17.5011 3.33366 17.5011H10.0003"
				stroke="#737980" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			<path
				d="M13.3337 8.5V4.16667C13.3337 3.72464 13.1581 3.30072 12.8455 2.98816C12.5329 2.67559 12.109 2.5 11.667 2.5H8.33366C7.89163 2.5 7.46771 2.67559 7.15515 2.98816C6.84259 3.30072 6.66699 3.72464 6.66699 4.16667V17.5"
				stroke="#737980" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			<circle cx="14" cy="14" r="5.25" stroke="#737980" strokeWidth="1.5"/>
			<path d="M14 12V16" stroke="#737980" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M16 14H12" stroke="#737980" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
		</svg>
	)
}