import {store, useTypedSelector} from "../../../store";
import applyIc from "../../../image/icon/apply_ic.svg";
import clockIc from "../../../image/icon/clock_ic.svg";
import {dayPublishConvert} from "../Table/TBody/TbodyInnerJobs";
import axios from "axios";
import {changeApplyPopupJobs, changeSuccessApplyPopupJobs} from "../../../store/reducers/companyJobsReducer";
import {getEnv} from "@urecruits/api";
import {memo, useState} from "react";
import { Link } from "react-router-dom";

const jobListFunc = state => state.jobs.companyJobs;

const {API_RECRUITMENT} = getEnv();
const token: string = localStorage.getItem("token");

const TableList = () => {
	const jobs = useTypedSelector(jobListFunc);

	const onApplyHandler = (jobId: number) => {
		axios.post(`${API_RECRUITMENT}/api/subscribe`, {
			applyJob: true,
			jobId: jobId,
		}, {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		}).then((res) => {
			if (res.status === 201) {
				store.dispatch(changeSuccessApplyPopupJobs(true));
			}
		});
	};

	return (
		<div className="job-list">
			<div className="job-list__inner">
				{
					jobs.map((item, index) => {
						return (
							<div className="job-list__item" key={index}>
								<div className="job-list__head">
									<Link to={`/job/${item.id}`} className="job-list__head__headline">
										{item.title}
									</Link>
									<div className="job-list__action">
										<SaveAction item={item}/>
										{
											item.applicationForm[0] ? (
													<div
														className="job-list__action__item"
														onClick={() => store.dispatch(changeApplyPopupJobs({
															visible: true,
															jobId: item.id,
															jobTitle: item.title,
														}))}
													>
														<img src={applyIc} alt="apply ic"/>
														Apply
													</div>
												)
												: (
													<div
														className="job-list__action__item"
														onClick={() => onApplyHandler(item.id)}
													>
														<img src={applyIc} alt="apply ic"/>
														Apply
													</div>
												)
										}
									</div>
								</div>
								<ul className="job-list__info">
									<li className="job-list__info__item">
										<span>{`${item.locations[0].state}, ${item.locations[0].city}`}</span>
									</li>
									<li className="job-list__info__item">
										{`${item.experience[0]}-${item.experience[1]} years`}
									</li>
									<li className="job-list__info__item">
										{`$${item.salaryMonth[0]} - $${item.salaryMonth[1]} PA`}
									</li>
									<li className="job-list__info__item">
										<img src={clockIc} alt="clock ic"/>
										{dayPublishConvert(item.postedOn) === 0 ? "today" : dayPublishConvert(item.postedOn) + " days ago"}
									</li>
								</ul>
								<ul className="job-list__tags">
									{
										item.skills.map((skill, index) => {
											return (
												<li className="job-list__tags__item" key={index}>
													{skill}
												</li>
											);
										})
									}
								</ul>
								<p className="job-list__about">{item.shortDescription}</p>
							</div>
						);
					})
				}
			</div>
		</div>
	);
};

export default TableList;

const SaveAction = memo(({item}: { item: any }) => {
	const token: string = localStorage.getItem("token");
	const config = {
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		},
	};

	const [saveStatus, setSaveStatus] = useState(item.subscribes ? !!item.subscribes.saveJob : false);

	const onSaveHandler = (jobId, status) => {
		axios.patch(`${API_RECRUITMENT}/api/subscribe/jobs/${jobId}`, {
			saveJob: status,
		}, config)
			.then(() => setSaveStatus(!saveStatus));
	};

	return (
		<div
			className={`job-list__action__item ${saveStatus ? "active" : ""}`}
			onClick={() => onSaveHandler(item.id, !saveStatus)}
		>
			<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M16.6663 18.3332L9.99967 13.7035L3.33301 18.3332V3.51836C3.33301 3.02721 3.53369 2.55619 3.8909 2.2089C4.24811 1.86161 4.7326 1.6665 5.23777 1.6665H14.7616C15.2668 1.6665 15.7512 1.86161 16.1084 2.2089C16.4657 2.55619 16.6663 3.02721 16.6663 3.51836V18.3332Z"
					stroke="#737980" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			</svg>
			Save
		</div>
	);
});