import {useTypedSelector} from "../../../../store";
import defaultAvatar from "../../../../image/icon/avatar.svg";
import {memo} from "react";
import {Link} from "react-router-dom";

const companiesListFunc = state => state.companies;

const TBodyInnerCompanies = ({horizontalScrollState}: { horizontalScrollState: boolean }) => {
	const table = useTypedSelector(companiesListFunc);

	return (
		<tbody className="table__tbody">
		{
			table?.companies?.map(item => {
				return <tr className="table__tr" key={item.id}>
					<td className={`table__td sticky ${horizontalScrollState ? "moved" : ""} companies__column__middle`}>
						<div className="companies__name">
							<img src={item.avatar || defaultAvatar} alt="avatar" className="companies__name__avatar"/>
							<Link to={`/candidate/company/${item.tenantId}`} className="companies__name__text link">{item.name}</Link>
						</div>
					</td>
					{
						table.companiesTabsFilter.map(value => {
							if (value.id === 2) {
								return value.active &&
									<td className="table__td actions companies__column__large" key={value.displayName}>
										<p
											className="companies__text companies__text--gray5">{`${item.addresses[0].country || ""}${item.addresses[0].location ? "," : ""} ${item.addresses[0].location || ""}`}</p>
									</td>;
							}
							if (value.id === 3) {
								return value.active &&
									<td className="table__td actions companies__column__large" key={value.displayName}>
										<p className="companies__text companies__text--gray5">{item.industry.label}</p>
									</td>;
							}
							if (value.id === 4) {
								return value.active &&
									<td className="table__td actions companies__column__large" key={value.displayName}>
										<p className="companies__text companies__text--gray5">{item.jobCount}</p>
									</td>;
							}
						})
					}
				</tr>;
			})
		}
		</tbody>
	);
};

export default memo(TBodyInnerCompanies);