import React, { Fragment, useEffect, useState } from "react";
import { useTypedSelector } from "../../store";
import { setCandidateAnswer, setSelectedQuestion } from "../../store/reducers/domainAssessmentReducer";
import { useDispatch } from "react-redux";
const getTableJobs = store => store.domain_assessment;

export const Question = () => {
   const {testData,selectedQuestion,totalQuestion,candidateAnswer} = useTypedSelector(getTableJobs);
  const [item, setItem]=useState(testData?.questions[selectedQuestion-1])
  const [selectedOptions, setSelectedOptions] = useState([]);
  const dispatch=useDispatch()

  useEffect(()=>{
    setItem(testData?.questions[selectedQuestion-1])
  },[selectedQuestion])

  const optionHandler = (getObj, type, questions, index) => {
  const data = type === "text" ? questions : JSON?.parse(questions);

  const [selectedOption, setSelectedOption] = useState(''); 

  const handleRadioChange = (event) => {    
    setSelectedOption(event.target.value); 
    const selectedValue=(data?.find(item => item.id===event.target.value));    
   if(selectedValue){ 
    const obj={
        "questionId": item?.id,
        "name": item?.name,
        "type": "single",
        "answers": JSON.stringify([selectedValue]),
        "score": selectedValue?.isCorrect ===  true ? getObj?.score:0
    }
    obj && dispatch(setCandidateAnswer(obj))
  }
  };

  const handleTextarea = (event) => {        
    if(event.target?.value){ 
      const obj={
          "questionId": item?.id,
          "name": item?.name,
          "type": "text",
          "answers": event.target?.value,
          "score": 0
      }
      obj && dispatch(setCandidateAnswer(obj))
    }
  };

  const handleOptionChange = (event) => {
    const value = event.target.value;

    const updatedOptions = selectedOptions.includes(value)
      ? selectedOptions.filter((option) => option !== value)
      : [...selectedOptions, value];
      let count=0
      let isCorrect=0
      let isFalse=0
      JSON?.parse(item?.answers).map((info)=>{
        if(info?.isCorrect===true) count++
      })
     
    setSelectedOptions(updatedOptions);
  
    const updatedData = data.filter(item => updatedOptions.includes(item.id));
    updatedData?.map((info)=>{
      info?.isCorrect ? isCorrect++ : isFalse++
    })

    const obj = {
      questionId: item?.id,
      name: item?.name,
      type: "multiple",
      answers: JSON.stringify(updatedData),
      score: count === isCorrect && isFalse === 0 ? getObj?.score : 0,
    };
    obj && dispatch(setCandidateAnswer(obj));
  };
  
    return (
      <Fragment>
        {type === "text" && (
          <div className="text">
            <textarea
              className="text__textarea"
              rows={5}
              defaultValue={candidateAnswer?.length > 0 && candidateAnswer
                .filter(info => info.questionId === item?.id)
                ?.map(info => info.answers)
                ?.join('\n') || ""
              }
              placeholder="Type Answer"
              onChange={handleTextarea}
              ></textarea>
          </div>
        )}

        {type === "multiple" && (
          <Fragment>
              <div className="multiple">
                {data?.map((item, index) => {
                  return (
                    <div key={index}>
                      <input
                        type="checkbox"
                        className="checkbox-checked"
                        value={item?.id}
                        onChange={handleOptionChange}
                        checked={selectedOptions.includes(item?.id)} 
                      />
                      <label> {item?.name}</label>
                      <br></br>
                    </div>
                  );
                })}
              </div>
          </Fragment>
        )}

        {type === "single" && (
          <Fragment key={item.id}>
              <div className="single">
                {data?.map((item, index) => {
                  return (
                    <div key={index}>
                     <input
                        type="radio"
                        className="radio-input"
                        name={item?.id}
                        value={item?.id}
                        checked={selectedOption === item?.id} 
                        onChange={handleRadioChange}
                      />
                      <label> {item?.name}</label>
                      <br></br>
                    </div>
                  );
                })}
              </div>
          </Fragment>
        )}
      </Fragment>
    );
  };

  return (
    <div className="domain-question">
      <div className="domain-question__header">
        <span ><span style={{color:"#029CA5"}}>{selectedQuestion}</span>{`/${totalQuestion}`}</span>
        <button style={{color:"#029CA5"}} onClick={()=>{
          dispatch(setSelectedQuestion(selectedQuestion + 1))
        }} disabled={selectedQuestion===totalQuestion}>Skip</button>
      </div>
      <div className="domain-question__container">
          <div className="domain-question__point__wrapper" key={item.id}>
            <b className="domain-question__point__wrapper__content">{item.name}</b>

           <div className="body">
           {optionHandler(item,item.type, item?.answers, item?.id)}
           </div>
          </div>
      </div>
    </div>
  );
};


