import { memo, useEffect, useState } from "react";
import { store, useTypedSelector } from "../../store";
import AsyncSelect from "react-select/async";
import { default as ReactSelect } from "react-select";
import { getEnv } from "@urecruits/api";
import { selectCustomStyle } from "../../styles/selectCustomStyle";
import {
	CustomRangeComponent,
} from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import axios from "axios";
import DatePicker from "react-datepicker";
import MultiSelect from "../CompanyJobs/MultySelect/MultiSelect";
import { CandidateJobsTableTabsEnums } from "../../enums/CandidateJobsEnums";
import { selectSearchFuncCompanies, selectSearchFuncIndustry } from "../../hook/searchFunc";
import {
	setCompanyNameFilterCandidateJobs,
	setEducationFilterCandidateJobs,
	setExperienceFilterCandidateJobs, setFunctionalAreaFilterCandidateJobs, setIndustryFilterCandidateJobs,
	setJobTypeFilterCandidateJobs, setPostedOnFilterCandidateJobs,
	setPreferableShiftFilterCandidateJobs,
	setSalaryMonthFilterCandidateJobs,
	setSalaryYearFilterCandidateJobs
} from "../../store/reducers/candidateJobsReducer";
import { LocationFilter } from "./CandidateJobsFilter/components";

const getFiltersFunc = (state) => state.candidate_jobs.filters;


const CompanyJobsFilter = ({activeTab}) => {
	const filter = useTypedSelector(getFiltersFunc);
	const [defaultSkills, setDefaultSkills] = useState([]);
	const {API_RECRUITMENT} = getEnv();

	useEffect(() => {
		const func = async () => {
			try {
				await axios.get(`${API_RECRUITMENT}/api/skills/`).then(res => setDefaultSkills(res.data));
			} catch (e) {
				console.error("Fetch error", e);
			}
		};
		func().then();
	}, []);


	return <>
		{
			activeTab === CandidateJobsTableTabsEnums.COMPANY_NAME &&
			<div className="table-filter-popup__right__content">
				<AsyncSelect
					cacheOptions
					loadOptions={(inputValue) =>
						inputValue.length > 0 ? selectSearchFuncCompanies(inputValue) : selectSearchFuncCompanies("")
					}
					defaultOptions
					value={filter.company}
					onChange={(option: any) => {
						store.dispatch(setCompanyNameFilterCandidateJobs(option));
					}}
					hideSelectedOptions={false}
					placeholder="Search by company"
					styles={selectCustomStyle}
					id="companySelect"
					instanceId="companySelect"
				/>
			</div>
		}
		{
			activeTab === CandidateJobsTableTabsEnums.JOB_LOCATION &&
			<div className="table-filter-popup__right__content">
				<LocationFilter />
			</div>
		}
		{
			activeTab === CandidateJobsTableTabsEnums.EXPERIENCE && <div className="table-filter-popup__right__content">
				<label className="table__range__label">Experience<span>(years)</span></label>
				<CustomRangeComponent rangeValue={filter.experience} max={50} min={0} setData={(state) => {
					store.dispatch(setExperienceFilterCandidateJobs(state));
				}}/>
			</div>
		}
		{
			activeTab === CandidateJobsTableTabsEnums.SALARY_RANGE && <div className="table-filter-popup__right__content">
				<label className="table__range__label">Salary Range<span>(per month)</span></label>
				<CustomRangeComponent rangeValue={filter.salaryMonth} max={20000} min={0} dollar={true} setData={(state) => {
					store.dispatch(setSalaryMonthFilterCandidateJobs(state));
				}}/>
				<label className="table__range__label mt">Salary Range<span>(per year)</span></label>
				<CustomRangeComponent rangeValue={filter.salaryYear} max={1000000} min={0} dollar={true} setData={(state) => {
					store.dispatch(setSalaryYearFilterCandidateJobs(state));
				}}/>
			</div>
		}
		{
			activeTab === CandidateJobsTableTabsEnums.MAIN_SKILLS && <MultiSelect defaultSkills={defaultSkills}/>
		}
		{
			activeTab === CandidateJobsTableTabsEnums.POSTED_ON &&
			<div className="table-filter-popup__right__content">
				<div className="filters-popup-mtm-datepicker">
					<div className="filters-popup-mtm-datepicker__item">
						<label className="filters-popup-mtm-datepicker__label">Choose date</label>
						<div className="filters-popup-mtm-datepicker__date">
							<DatePicker
								selected={filter.postedOn ?  new Date(filter.postedOn): new Date()}
								showYearDropdown={true}
								scrollableYearDropdown={true}
								yearDropdownItemNumber={70}
								onChange={(date: any) => {
									store.dispatch(setPostedOnFilterCandidateJobs(date));
								}}
								dateFormat="MMMM d, yyyy"
								maxDate={new Date()}
								placeholderText="Date"
								id={"datePicker"}
							/>
						</div>
					</div>
				</div>
			</div>
		}
		{
			activeTab === CandidateJobsTableTabsEnums.EDUCATION &&
			<div className="table-filter-popup__right__content">
				<ReactSelect
					options={educationOption}
					value={filter.education}
					onChange={(option: any) => {
						store.dispatch(setEducationFilterCandidateJobs(option));
					}}
					hideSelectedOptions={false}
					placeholder="Search by education"
					styles={selectCustomStyle}
					id="educationSelect"
					instanceId="educationSelect"
				/>
			</div>
		}
		{
			activeTab === CandidateJobsTableTabsEnums.JOB_TYPE &&
			<div className="table-filter-popup__right__content">
				<ReactSelect
					options={jobTypeOption}
					value={filter.education}
					onChange={(option: any) => {
						store.dispatch(setJobTypeFilterCandidateJobs(option));
					}}
					hideSelectedOptions={false}
					placeholder="Search by job type"
					styles={selectCustomStyle}
					id="jobTypeSelect"
					instanceId="jobTypeSelect"
				/>
			</div>
		}
		{
			activeTab === CandidateJobsTableTabsEnums.PREFERABLE_SHIFT &&
			<div className="table-filter-popup__right__content">
				<ReactSelect
					options={preferableShiftOption}
					value={filter.preferableShift}
					onChange={(option: any) => {
						store.dispatch(setPreferableShiftFilterCandidateJobs(option));
					}}
					hideSelectedOptions={false}
					placeholder="Search by preferable shift option"
					styles={selectCustomStyle}
					id="preferableShift"
					instanceId="preferableShift"
				/>
			</div>
		}
		{
			activeTab === CandidateJobsTableTabsEnums.INDUSTRY_TYPE &&
			<div className="table-filter-popup__right__content">
				<AsyncSelect
					cacheOptions
					loadOptions={(inputValue) =>
						inputValue.length > 0 ? selectSearchFuncIndustry(inputValue) : selectSearchFuncIndustry("")
					}
					defaultOptions
					value={filter.industryType}
					onChange={(option: any) => {
						store.dispatch(setIndustryFilterCandidateJobs(option));
					}}
					hideSelectedOptions={false}
					placeholder="Search by industry"
					styles={selectCustomStyle}
					id="industryType"
					instanceId="industryType"
				/>
			</div>
		}
		{
			activeTab === CandidateJobsTableTabsEnums.FUNCTIONAL_AREA &&
			<div className="table-filter-popup__right__content">
				<ReactSelect
					options={functionAreaOption}
					value={filter.functionalArea}
					onChange={(option: any) => {
						store.dispatch(setFunctionalAreaFilterCandidateJobs(option));
					}}
					hideSelectedOptions={false}
					placeholder="Search by functional area"
					styles={selectCustomStyle}
					id="functionalArea"
					instanceId="functionalArea"
				/>
			</div>
		}
	</>;
};
export default memo(CompanyJobsFilter);


const educationOption = [
	{
		value: "High School",
		label: "High School",
	},
	{
		value: "Associate degree",
		label: "Associate degree",
	},
	{
		value: "Bachelor's degree",
		label: "Bachelor's degree",
	},
	{
		value: "Master's degree",
		label: "Master's degree",
	},
	{
		value: "Doctorate degree",
		label: "Doctorate degree",
	},
	{
		value: "Professional degree",
		label: "Professional degree",
	},
];
const preferableShiftOption = [
	{ value: "General Shift", label: "General Shift" },
	{ value: "Morning Shift", label: "Morning Shift" },
	{ value: "Afternoon Shift", label: "Afternoon Shift" },
	{ value: "Evening Shift", label: "Evening Shift" },
	{ value: "Night Shift", label: "Night Shift" },
	{ value: "Flexible Shift", label: "Flexible Shift" },
];
const jobTypeOption = [
	{value: "Full-Time Employees", label: "Full-Time Employees"},
	{value: "Part-Time Employees", label: "Part-Time Employees"},
	{value: "Temporary Employees", label: "Temporary Employees"},
	{value: "Seasonal Employees", label: "Seasonal Employees"},
	{value: "Independent Contractors", label: "Independent Contractors"},
	{value: "Freelancers", label: "Freelancers"},
	{value: "Temporary workers", label: "Temporary workers"},
	{value: "Consultants", label: "Consultants"},
];

const functionAreaOption = [
	{value: "Executive Management", label: "Executive Management"},
	{value: "Human Resources (HR)", label: "Human Resources (HR)"},
	{value: "Finance and Accounting", label: "Finance and Accounting"},
	{value: "Human resources", label: "Human resources"},
	{value: "Sales and Marketing", label: "Sales and Marketing"},
	{value: "Information Technology (IT)", label: "Information Technology (IT)"},
	{value: "Research and Development (R&D)", label: "Research and Development (R&D)"},
	{value: "Customer Service", label: "Customer Service"},
	{value: "Legal and Compliance", label: "Legal and Compliance"},
	{value: "Procurement and Purchasing", label: "Procurement and Purchasing"},
	{value: "Public Relations (PR)", label: "Public Relations (PR)"},
	{value: "Quality Assurance (QA)", label: "Quality Assurance (QA)"},
	{value: "Facilities Management", label: "Facilities Management"},
	{value: "Strategic Planning", label: "Strategic Planning"},
	{value: "Training and Development", label: "Training and Development"},
	{value: "Internal Audit", label: "Internal Audit"},
	{value: "Investor Relations", label: "Investor Relations"},
];