import {memo, useState} from "react";
import defaultAvatar from "../../../../image/icon/avatar.svg";
import SingleItem from "../../../Global/table/SingleMobileItem";
import {ICompanyUser} from "../../../../types/redux/companies";
import {CompaniesTableTabsEnums} from "../../../../enums/CompaniesTableEnums";

const MobileTableItem = ({item}: { item: ICompanyUser }) => {
	const [openItem, setOpenItem] = useState(false);

	return <div className="table-mobile__item">
		<div className={`table-mobile__top ${openItem ? "active" : ""}`} onClick={() => {
			setOpenItem(prev => !prev);
		}}>
			<div className="table-mobile__top__left">
				<img src={item.avatar || defaultAvatar} alt="avatar" className="table-mobile__avatar"/>
				<p className="table-mobile__top__name">{item.name}</p>
			</div>
			<ArrowSvg/>
		</div>
		<div className={`table-mobile__item__body ${openItem ? "open" : ""}`}>
			{
				(item.addresses[0].country || item.addresses[0].location) && (
					<SingleItem title={CompaniesTableTabsEnums.COMPANY_LOCATION + ":"}
					            text={`${item.addresses[0].country || ""}${item.addresses[0].location ? "," : ""} ${item.addresses[0].location || ""}`} textStyle={"table-mobile__item__text"}/>
				)
			}
			<SingleItem title={CompaniesTableTabsEnums.MAIN_INDUSTRY + ":"} text={item.industry.label} textStyle={"table-mobile__item__text"}/>
			<SingleItem title={CompaniesTableTabsEnums.OPEN_JOBS + ":"} text={item.jobCount} textStyle={"table-mobile__item__text"}/>
		</div>
	</div>;
};
export default memo(MobileTableItem);

const ArrowSvg = () => {
	return <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_6751_20970)">
			<path d="M1 3L5 7L9 3" stroke="#5F6E80" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
		</g>
		<defs>
			<clipPath id="clip0_6751_20970">
				<rect width="10" height="10" fill="white" transform="translate(0 10) rotate(-90)"/>
			</clipPath>
		</defs>
	</svg>;
};