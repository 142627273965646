import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTypedSelector } from "../../store";
import { setModal } from '../../store/reducers/domainAssessmentReducer';
import { CodingModalsEnum } from '../../types/redux/domainAssessment';
const getTableJobs = store => store.domain_assessment;

function CountdownTimer({submitExecuter}) {
  const {testData,selectedQuestion,totalQuestion,candidateAnswer,isStartTest} = useTypedSelector(getTableJobs);
  
  function durationToMinutes(durationStr) {
    return (parseInt(durationStr.match(/\d+\s*Hr/g)?.[0]) || 0) * 60 + (parseInt(durationStr.match(/\d+\s*min/g)?.[0]) || 0);
  }
  
  const dispatch=useDispatch()
  const [time, setTime] = useState(durationToMinutes(testData?.duration)  * 60);

useEffect(() => {
if(isStartTest){
  const countdownInterval = setInterval(() => {
    if (time > 0) {
      setTime(time - 1);
    } else {
      submitExecuter();
      dispatch(setModal(CodingModalsEnum.congratulations))
      clearInterval(countdownInterval);
    }
  }, 1000);

  return () => {
    clearInterval(countdownInterval);
  };
}
    
  }, [time]);

  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = time % 60;

  return (
    <div style={{ fontSize: '16px', pointerEvents: 'none',fontWeight:"700" , lineHeight:"16px" }}>
      <div style={{ display: 'inline-block' }}>
        <span>{String(hours).padStart(2, '0')}</span>:
        <span>{String(minutes).padStart(2, '0')}</span>:
        <span>{String(seconds).padStart(2, '0')}</span>
      </div>
    </div>
  );
}

export default CountdownTimer;
