import {CandidateJobsTableTabsEnums} from "../../enums/CandidateJobsEnums";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ICandidateJobsTable} from "../../types/redux/candidateJobs";
import {IAsyncCheckBoxList, ITabsFilter} from "../../types/global/global";
import { IColReorder } from "@urecruits/recruitments/types/global/global";

export const defaultFiltersCandidateJobs = {
	searchValue: "",
	sortBy: "id",
	sortType: "ASC",
	locations: [],
	postedOn: "",
	experience: [0, 50],
	salaryMonth: [0, 20000],
	salaryYear: [0, 1000000],
	education: "",
	skills: [],
	company: "",
	jobType: "",
	industryType: "",
	preferableShift: "",
	functionalArea: "",
};

const initialStateCandidateJobs: ICandidateJobsTable = {
	tableView: "table",
	noResultSearch: false,
	tableEmpty: false,
	// candidateJobs: [],
	filters: defaultFiltersCandidateJobs,
	filterType:"",
	pagination: {
		currentPage: 1,
		limit: 10,
		totalCount: 0,
	},
	colReorder:[],
	filterInfo: {
		tabs: [
			CandidateJobsTableTabsEnums.COMPANY_NAME,
			CandidateJobsTableTabsEnums.JOB_LOCATION,
			CandidateJobsTableTabsEnums.EXPERIENCE,
			CandidateJobsTableTabsEnums.SALARY_RANGE,
			CandidateJobsTableTabsEnums.MAIN_SKILLS,
			CandidateJobsTableTabsEnums.POSTED_ON,
			CandidateJobsTableTabsEnums.EDUCATION,
			CandidateJobsTableTabsEnums.JOB_TYPE,
			CandidateJobsTableTabsEnums.PREFERABLE_SHIFT,
			CandidateJobsTableTabsEnums.INDUSTRY_TYPE,
			CandidateJobsTableTabsEnums.FUNCTIONAL_AREA,
		],
	},
	applyPopup: {
		visible: false,
		jobId: 0,
		jobTitle: "",
	},
};

export const candidateJobs = createSlice({
	name: "candidateJobs",
	initialState: initialStateCandidateJobs,
	reducers: {
		setTableEmptyCandidateJobs: (state, action: PayloadAction<boolean>) => {
			state.tableEmpty = action.payload;
		},
		setNoResultSearchCandidateJobs: (state, action: PayloadAction<boolean>) => {
			state.noResultSearch = action.payload;
		},
		setCurrentPageCandidateJobs: (state, action: PayloadAction<number>) => {
			state.pagination.currentPage = action.payload;
		},
		setLimitCandidateJobs: (state, action: PayloadAction<number>) => {
			state.pagination.limit = action.payload,
				state.pagination.currentPage = 1;
		},
		// setOrderCandidateJobs: (state, action: PayloadAction<Array<ITabsFilter>>) => {
		// 	state.tabFilter = action.payload;
		// },
		setOrderCandidateJobsColumns: (state, action: PayloadAction<Array<IColReorder>>) => {
			state.colReorder = action.payload;
		},
		setTotalCountCandidateJobs: (state, action: PayloadAction<number>) => {
			state.pagination.totalCount = action.payload;
		},
		setSortTypeFilterCandidateJobs: (state, action: PayloadAction<string>) => {
			state.filters.sortType = action.payload;
		},
		setSortByFilterCandidateJobs: (state, action: PayloadAction<string>) => {
			state.filters.sortBy = action.payload;
		},
		setSearchValueCandidateJobs: (state, action: PayloadAction<string>) => {
			state.filters.searchValue = action.payload;
		},
		setLocationFilterCandidateJobs: (state, action: PayloadAction<Array<IAsyncCheckBoxList>>) => {
			state.filters.locations = action.payload;
		},
		setPostedOnFilterCandidateJobs: (state, action: PayloadAction<string>) => {
			state.filters.postedOn = action.payload;
		},
		setExperienceFilterCandidateJobs: (state, action: PayloadAction<Array<number>>) => {
			state.filters.experience = action.payload;
		},
		setSalaryMonthFilterCandidateJobs: (state, action: PayloadAction<Array<number>>) => {
			state.filters.salaryMonth = action.payload;
		},
		setSalaryYearFilterCandidateJobs: (state, action: PayloadAction<Array<number>>) => {
			state.filters.salaryYear = action.payload;
		},
		setEducationFilterCandidateJobs: (state, action: PayloadAction<string>) => {
			state.filters.education = action.payload;
		},
		setSkillsFilterCandidateJobs: (state, action: PayloadAction<Array<string>>) => {
			state.filters.skills = action.payload;
		},
		setCompanyNameFilterCandidateJobs: (state, action: PayloadAction<string>) => {
			state.filters.company = action.payload;
		},
		setJobTypeFilterCandidateJobs: (state, action: PayloadAction<string>) => {
			state.filters.jobType = action.payload;
		},
		setPreferableShiftFilterCandidateJobs: (state, action: PayloadAction<string>) => {
			state.filters.preferableShift = action.payload;
		},
		setIndustryFilterCandidateJobs: (state, action: PayloadAction<string>) => {
			state.filters.industryType = action.payload;
		},
		setFunctionalAreaFilterCandidateJobs: (state, action: PayloadAction<string>) => {
			state.filters.functionalArea = action.payload;
		},
		setTableViewCandidateJobs: (state, action: PayloadAction<string>) => {
			state.tableView = action.payload;
		},
		setFilterTypeCandidateJobs: (state, action: PayloadAction<"" | "applied" | "saved" | "matched">) => { 
			state.filterType = action.payload
		},
		changeApplyPopupCandidateJobs: (state, action: PayloadAction<any>) => {
			state.applyPopup = action.payload;
		},
	},
});

export const {
	setTableEmptyCandidateJobs,
	setNoResultSearchCandidateJobs,
	setCurrentPageCandidateJobs,
	setLimitCandidateJobs,
	// setOrderCandidateJobs,
	setOrderCandidateJobsColumns,
	setTotalCountCandidateJobs,
	setSortTypeFilterCandidateJobs,
	setSortByFilterCandidateJobs,
	setSearchValueCandidateJobs,
	setLocationFilterCandidateJobs,
	setPostedOnFilterCandidateJobs,
	setExperienceFilterCandidateJobs,
	setSalaryMonthFilterCandidateJobs,
	setSalaryYearFilterCandidateJobs,
	setEducationFilterCandidateJobs,
	setSkillsFilterCandidateJobs,
	setCompanyNameFilterCandidateJobs,
	setJobTypeFilterCandidateJobs,
	setPreferableShiftFilterCandidateJobs,
	setIndustryFilterCandidateJobs,
	setFunctionalAreaFilterCandidateJobs,
	setTableViewCandidateJobs,
	changeApplyPopupCandidateJobs,
	setFilterTypeCandidateJobs
} = candidateJobs.actions;

export default candidateJobs.reducer;