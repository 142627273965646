import axios from "axios";

const fetchData = async (api) => {
  try {
    const token: string = localStorage.getItem("token");
    const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
    const res = await axios.get(api, config);
    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    throw Error("Unexpected Error");
  }
};

export const postData = async (api, body) => {
  try {
    const token: string = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const res = await axios.post(api, body, config);
    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    throw Error("Unexpected Error");
  }
};
export const deleteData = async (api, data) => {
  const token: string = localStorage.getItem("token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const res = await axios.delete(api, { ...config, data: data });

  if (res && res.data) {
    return res.data;
  }
};
export const patchData = async (api, data) => {
  const token: string = localStorage.getItem("token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const res = await axios.patch(api, data, config);
  if (res && res.data) {
    return res.data;
  }
};
export default fetchData;
