import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ICandidateScoreboardTable } from "../../types/redux/candidateScoreboard";


const initialstateCandidateScoreboard: ICandidateScoreboardTable = {
  noResultSearch: false,
  tableEmpty: false,
  searchValue: '',
  pagination: {
    currentPage: 1,
    limit: 10,
    totalCount: 0
  },
  tableView: "table",
  sortBy: "totalScores",
  sortType: "ASC",
}

export const candidateScoreboard = createSlice({
  name: "candidateScoreboard",
  initialState: initialstateCandidateScoreboard,
  reducers: {
    setTableEmptyCandidateScoreboard: (state, action: PayloadAction<boolean>) => {
      state.tableEmpty = action.payload;
    },
    setNoResultSearchCandidateScoreboard: (state, action: PayloadAction<boolean>) => {
      state.noResultSearch = action.payload;
    },
    setCurrentPageCandidateScoreboard: (state, action: PayloadAction<number>) => {
      state.pagination.currentPage = action.payload;
    },
    setLimitCandidateScoreboard: (state, action: PayloadAction<number>) => {
      state.pagination.limit = action.payload,
        state.pagination.currentPage = 1;
    },
    setTotalCountCandidateScoreboard: (state, action: PayloadAction<number>) => {
      state.pagination.totalCount = action.payload;
    },
    setSearchValueCandidateScoreboard: (state, action: PayloadAction<string>) => {
      state.searchValue = action.payload
    },
    setSortByFilterCandidateScoreboard: (state, action: PayloadAction<string>) => {
      state.sortBy = action.payload
    },
    setSortTypeFilterCandidateScoreboard: (state, action: PayloadAction<string>) => {
      state.sortType = action.payload
    },
  }
})

export const { setTableEmptyCandidateScoreboard,
  setNoResultSearchCandidateScoreboard,
  setCurrentPageCandidateScoreboard,
  setLimitCandidateScoreboard,
  setTotalCountCandidateScoreboard,
  setSearchValueCandidateScoreboard,
  setSortByFilterCandidateScoreboard,
  setSortTypeFilterCandidateScoreboard
} = candidateScoreboard.actions;
export default candidateScoreboard.reducer;