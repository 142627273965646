import { useEffect, useState } from 'react';
import AsyncSelect from "react-select/async";
import { store, useTypedSelector } from "../../../../../store";
import Option from "../../../../Global/SelectOptions";
import { selectSearchFuncLocationLimit } from "@urecruits/api";
import { selectCustomStyle } from '../../../../../styles/selectCustomStyle';
import { setLocationFilterCandidateJobs } from '../../../../../store/reducers/candidateJobsReducer';

const getFiltersFunc = (state) => state.jobs.filters;

const LocationFilter = () => {
        const filter = useTypedSelector(getFiltersFunc);

        const [hasMoreLocation, setHasMoreLocation] = useState(false);
        const [offsetLocation, setOffsetLocation] = useState(30);
        const [searchValueLocation, setSearchValueLocation] = useState(null);
        const [optionsLocation, setOptionsLocation] = useState([]);

        const loadOptionsLocation = async (inputValue) => {
                setSearchValueLocation(inputValue)
                if (hasMoreLocation) {
                        try {
                                const response = await selectSearchFuncLocationLimit(`find=${inputValue && inputValue}&offset=${offsetLocation}`);
                                if (response.length > 0) {
                                        const newOptions = response.map((item) => ({ value: item.id, label: item.label }));
                                        setOffsetLocation(offsetLocation + 30);
                                        const data = [...optionsLocation, ...newOptions];
                                        setOptionsLocation(data)
                                        setHasMoreLocation(false);
                                        return data
                                } else {
                                        setHasMoreLocation(false);
                                        return optionsLocation
                                }
                        } catch (error) {
                                console.error('Error loading more options:', error);
                        }
                } else {
                        const data = await selectSearchFuncLocationLimit(`find=${inputValue && inputValue}`);
                        setOptionsLocation(data);
                        return (data);
                }
        };

        useEffect(() => {
                if (hasMoreLocation || optionsLocation?.length === 0) {
                        loadOptionsLocation(searchValueLocation !== null ? searchValueLocation : "");
                }
        }, [hasMoreLocation, optionsLocation?.length === 0]);

        return (
                <AsyncSelect
                        isMulti
                        components={{
                                Option
                        }}
                        cacheOptions
                        loadOptions={loadOptionsLocation}
                        defaultOptions={optionsLocation && optionsLocation}
                        options={optionsLocation && optionsLocation}
                        isLoading={hasMoreLocation}
                        hideSelectedOptions={false}
                        closeMenuOnSelect={false}
                        onBlur={() => {
                                setOffsetLocation(30);
                                loadOptionsLocation("")
                        }}
                        value={filter.location}
                        onChange={(option: any) => {
                                store.dispatch(setLocationFilterCandidateJobs(option));
                        }}
                        placeholder={"Search by job location"}
                        styles={selectCustomStyle}
                        id="locationSelect"
                        instanceId="locationSelect"
                        onMenuScrollToBottom={(e) => {
                                !hasMoreLocation && setHasMoreLocation(true)
                        }}
                />
        )
}

export default LocationFilter