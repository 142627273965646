import { SmallLoaderComponent } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import { getEnv } from "@urecruits/api";
import axios from 'axios';
import { Fragment, useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from 'react-router-dom'
import CloseSVG from "../components/SVG/CloseSVG";
import ActionPopupCandidateOffers from "../components/CandidateJobs/Popups/ActionPopupCandidateOffers";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";

const { API_RECRUITMENT } = getEnv()
const token = localStorage.getItem("token");
const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
};

const handleRejectOffer = (offerId, companyId) => {
  try {
    axios.patch(`${API_RECRUITMENT}/api/candidate-offers/${companyId}/${offerId}`,
      {
        candidateStatus: 'rejected'
      },
      config)
  } catch (err) {
    console.log("error", err)
  }
}
const CandidateOfferViewScreen = () => {
  const { offerId } = useParams()
  const [searchParamas, setSearchParam] = useSearchParams()
  const popupType = searchParamas.get("type")

  const [pdfContent, setPdfContent] = useState<null | string>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [toastNotification, setToastNotification] = useState({state:false,message:""})
  const [offerData, setOfferData] = useState<any>()

  useEffect(() => {
    const setPdfPreview = async () => {
      try{

        const pdfBlob = await fetchPdf(offerId)
      if (pdfBlob) {
        Promise.resolve(setPdfContent(URL.createObjectURL(pdfBlob)));
      }
    }catch(error){
      console.log(error)
      setToastNotification({state:true,message:error.message});
    }
    };

    try {
      getOfferData(offerId).then(data => setOfferData(data))
      setPdfPreview().then(data => {
        setIsLoading(false)
      });
    } catch (err) { }

    return () => URL.revokeObjectURL(pdfContent as string);
  }, [offerId])

  const handleAcceptAction = () => {
    setSearchParam({ "type": "accept" })
  }
  const handleRejectAction = () => {
    setSearchParam({ "type": "reject" })
  }

  const handleToastNotificationClose = () => {
    setToastNotification({
      state: false,
      message: "",
    });
  };

  const action = (
    <Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleToastNotificationClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </Fragment>
  );

  return <>
  <Snackbar
        open={toastNotification.state}
        onClose={handleToastNotificationClose}
        message={toastNotification.message}
        action={action}
        sx={{
          "& .css-1eqdgzv-MuiPaper-root-MuiSnackbarContent-root": {
            background:
              "linear-gradient(125.2deg, #099c73 8.04%, #015462 127.26%)",
            color: "white",
          },
        }}
      />
    <section className="candidate-offer-preview">
      <div className="candidate-offer-preview__head">
        <div className="candidate-offer-preview__head__left">
          <h2 className="candidate-offer-preview__head__headline">
            Offer Letter {offerData && `: ${offerData.jobTitle || ""}`}
          </h2>
        </div>
        <div className="candidate-offer-preview__head__right">
          <Link to="/candidate/offers" >
            <p className="candidate-offer-preview__head button">
              <CloseSVG />
              <span>
                Close
              </span>
            </p>
          </Link>
        </div>
      </div>
      <div className="candidate-offer-preview__content">
        {isLoading ? <SmallLoaderComponent />
          : pdfContent &&
          <embed
            src={pdfContent}
            type="application/pdf"
            width="100%"
          />
        }
      </div>
      {offerData && offerData.candidateStatus !== 'accepted' &&
        <div className="candidate-offer-preview__footer">
          <button className="candidate-offer-preview__footer__action" onClick={handleAcceptAction}>Accept & Sign Offer</button>
          <button className="candidate-offer-preview__footer__action reject" onClick={handleRejectAction}>Reject</button>
        </div>
      }
    </section>
    {
      popupType && <ActionPopupCandidateOffers type={popupType} offerId={offerId} onReject={() => handleRejectOffer(offerId, offerData.companyId)} />
    }
  </>
}
export default CandidateOfferViewScreen

const fetchPdf = async (offerId) => {
  const url = `${API_RECRUITMENT}/api/candidate-offers/hellosign/signature-document/${offerId}`;
  const response = await axios.get(url, {
    ...config,
    responseType: "blob",
  });
  return response.data;
};

const getOfferData = async (offerId) => {
  const response = await axios.get(`${API_RECRUITMENT}/api/candidate-offers/${offerId}`, config)
  return response.data
}