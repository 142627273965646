import { ICandidateOffersTable } from "../../types/redux/candidateOffers";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";


const initialStateCandidateOffers: ICandidateOffersTable = {
  noResultSearch: false,
  tableEmpty: false,
  searchValue: '',
  pagination: {
    currentPage: 1,
    limit: 10,
    totalCount: 0
  },
  tableView: "table"
}

export const candidateOffers = createSlice({
  name: "candidateOffers",
  initialState: initialStateCandidateOffers,
  reducers: {
    setTableEmptyCandidateOffers: (state, action: PayloadAction<boolean>) => {
      state.tableEmpty = action.payload;
    },
    setNoResultSearchCandidateOffers: (state, action: PayloadAction<boolean>) => {
      state.noResultSearch = action.payload;
    },
    setCurrentPageCandidateOffers: (state, action: PayloadAction<number>) => {
      state.pagination.currentPage = action.payload;
    },
    setLimitCandidateOffers: (state, action: PayloadAction<number>) => {
      state.pagination.limit = action.payload,
        state.pagination.currentPage = 1;
    },
    setTotalCountCandidateOffers: (state, action: PayloadAction<number>) => {
      state.pagination.totalCount = action.payload;
    },
    setSearchValueCandidateOffers: (state, action: PayloadAction<string>) => {
      state.searchValue = action.payload
    }
  }
})

export const { setTableEmptyCandidateOffers,
  setNoResultSearchCandidateOffers,
  setCurrentPageCandidateOffers,
  setLimitCandidateOffers,
  setTotalCountCandidateOffers,
  setSearchValueCandidateOffers
} = candidateOffers.actions;
export default candidateOffers.reducer;