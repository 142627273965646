export enum CompanyJobsTableTabsEnums {
	JOB_TITLE = "Job title",
	JOB_LOCATION = "Job Location",
	EXPERIENCE = "Experience",
	SALARY_RANGE = "Salary Range",
	MAIN_SKILLS = "Main Skills",
	POSTED_ON = "Posted on",
	ACTIONS = "Actions",
}

export enum CompanyJobsFilters{
	Location='Location',
	Experience='Experience',
	Salary='Salary',
	Skills='Skills',
	Posted='Posted on'
}