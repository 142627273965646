import {useTypedSelector} from "../../../../store";
import MobileTableItem from "./MobileTableItem";
import {memo} from "react";

const getCompanyJobs = state => state.jobs.companyJobs;

const MobileTable = () => {
	const companyJobs = useTypedSelector(getCompanyJobs);

	return (
		<div className="table-mobile">
			{
				companyJobs.map(item => {
					return <MobileTableItem key={item.id} item={item}/>;
				})
			}
		</div>
	);
};
export default memo(MobileTable);