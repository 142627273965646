import {memo} from "react";
import {PieChart, Pie, Cell} from "recharts";

// TODO: need to add color generator
const COLORS = ["#029CA5", "#FF8541", "#737980", "#E9C715", "#FE4672"];

const CircleChart = ({data}) => {

	return (
		<div className="circle-chart">
			<p className="circle-chart__headline">
				Jobs Summary
			</p>
			<div className="circle-chart__inner">
				<div className="circle-chart__left">
					<PieChart width={192} height={192}>
						<Pie
							data={data.summary}
							cx={90}
							cy={90}
							innerRadius={74}
							outerRadius={90}
							dataKey="value"
						>
							{data.summary?.map((entry, index) => (
								<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>
							))}
						</Pie>
					</PieChart>
					<div className="circle-chart__info">
						<p className="circle-chart__info__count">{data.totalJobsCount}</p>
						<p className="circle-chart__info__label">total jobs</p>
					</div>
				</div>
				<div className="circle-chart__right">
					<ul className="circle-chart__list">
						{
							data.summary.map((item, index) => {
								return (
									<li className="circle-chart__item" key={index}>
										<span className="circle-chart__point" style={{backgroundColor: COLORS[index % COLORS.length]}}></span>
										<p className="circle-chart__name">{item.name} <span>{item.value}</span></p>
									</li>
								);
							})
						}
					</ul>
				</div>
			</div>
		</div>
	);
};

export default memo(CircleChart);