import defaultAvatar from "../../../image/icon/avatar.svg";
import {useTypedSelector} from "../../../store";
import {Link} from "react-router-dom";

const companiesListFunc = state => state.companies;

const TableList = () => {
	const table = useTypedSelector(companiesListFunc);

	return (
		<div className="companies-list">
			<div className="companies-list__inner">
				{
					table?.companies?.map((item, index) => {
						return (
							<div className="companies-list__item" key={index}>
								<div className="companies-list__head">
									<div className="companies-list__head__left">
										<div className="companies-list__head__logo">
											<img src={item.avatar || defaultAvatar} alt={item.name}/>
										</div>
									</div>
									<div className="companies-list__head__right">
										<Link to={`/candidate/company/${item.tenantId}`} className="companies-list__head__headline">{item.name}</Link>
										<div className="companies-list__information">
											{
												(item.addresses[0].country || item.addresses[0].location) && (
													<div className="companies-list__information__item">
														{`${item.addresses[0].country || ""} ${item.addresses[0].location || ""}`}
													</div>
												)
											}
											<div className="companies-list__information__item">
												{item.jobCount} jobs
											</div>
										</div>
									</div>
								</div>
								<div className="companies-list__body">
									<div className="companies-list__tags">
										<div className="companies-list__tags__item">
											{item.industry.label}
										</div>
									</div>
									{
										item.about && (
											<div className="companies-list__body__description" dangerouslySetInnerHTML={{__html: `${item.about}`}}>
											</div>
										)
									}
								</div>
							</div>
						);
					})
				}
			</div>
		</div>
	);
};

export default TableList;