import {LineChart as RechartsLineChart, Line, ResponsiveContainer} from "recharts";
import {memo} from "react";

export const calcPercent = (firsValue, lastValue) => {
	if (firsValue && lastValue) {
		const percent = (lastValue / 7) * 100;
		const result = Math.trunc(percent * 100) / 100;

		return `${firsValue > lastValue ? "-" : "+"}${result}%`;
	}
};


const LineChart = ({data, name, position}) => {
	const growth = data[0]?.value < data[data.length - 1]?.value;

	return (
		<div className={`line-chart ${position}`}>
			<div className="line-chart__head">
				<p className="line-chart__head__title">
					{name}
				</p>
				<p className="line-chart__head__days">
					last 7 days
				</p>
			</div>
			<div className="line-chart__body">
				<div className="line-chart__body__left">
					<p className="line-chart__body__value">
						{data.reduce((acc,next)=>acc+next.value,0)}
					</p>
					<p className={`line-chart__body__percent ${growth ? "growth" : ""}`}>
						{calcPercent(data[0]?.value|| 1,data.reduce((acc,next)=>acc+next.value,0))}
					</p>
				</div>
				<div className="line-chart__body__right">
					<ResponsiveContainer width="100%" height="100%">
						<RechartsLineChart width={165} height={48} data={data}>
							<Line type="monotone" dataKey="value" stroke={growth ? "#099C73" : "#FE4672"} strokeWidth={2}/>
						</RechartsLineChart>
					</ResponsiveContainer>
				</div>
			</div>
		</div>
	);
};
export default memo(LineChart);