import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import useModal from "../../../../assessment/live-coding/hooks/modal/use-modal";

import * as types from "../../../types/redux/domainAssessment";
import React from "react";
import { setModal, setModalClose } from "../../../store/reducers/domainAssessmentReducer";
import { useTypedSelector } from "../../../store";

interface Props {
  children?: React.ReactNode;
  isOpen?: types.CodingModalsEnum | null;
  className?: string;
  closeBtn?: boolean;
  onOpen?: boolean;
  title?: string;
}

export const Modal: React.FC<Props> = (props) => {  
  const { children, className, closeBtn = true, isOpen = null, title } = props;
  const {modalClose} = useTypedSelector(state => state.domain_assessment);

  useModal(isOpen);
  const dispatch = useDispatch();
  const ref = useRef<any>(null);

  const finalClassName = [`Modal`];
  if (className) finalClassName.push(className);

  const handleClose = () => {
    dispatch(setModal(null));
  };
  
  useEffect(()=>{
    if(modalClose===true){
      handleClose();
      dispatch(setModalClose(false))
    }
  },[modalClose])

  useEffect(() => {
    const close = (e) => {
      if (e.target == ref.current) {
        handleClose();
      }
    };
    // window.addEventListener("click", close);

    return () => {
      window.removeEventListener("click", close);
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      ref.current.style.display = "flex";
    } else {
      ref.current.style.display = "none";
    }
  }, [isOpen]);
  return (
    <div className={finalClassName.join(" ")} ref={ref}>
      <div className="Modal__step">
        <div className="Modal__header">
          {title && <div className="Modal__title">{title}</div>}
        </div>
        {children}
      </div>
    </div>
  );
};

export default Modal;
