import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IDomainAssessment } from "../../types/redux/domainAssessment";

const initialStateDomainAssessment: IDomainAssessment = {
  jobId: null,
  assessmentId: null,
  candidateAnswer: [{
	questionId:null,
	name:"",
	type:"",
	answer:"",
	score:0
  }],
  totalScore:0,
  duration:"",
  totalQuestion:null,
  selectedQuestion:1,
  testData:null,
  isStartTest:false,
  allQuestionScore:0,
  applyPopup: false,
  successApplyPopup: false,
  modal: null,
  modalClose:false
}

export const domainAssessment = createSlice({
	name: 'domain_assessment',
	initialState: initialStateDomainAssessment,
	reducers: {
		setJobId: (state, action: PayloadAction<number>) => {
			state.jobId = action.payload
		},
		setAssessmentId: (state, action: PayloadAction<number>) => {
			state.assessmentId = action.payload
		},
		setTotalScore: (state, action: PayloadAction<any>) => {
			state.totalScore = action.payload
		},
		setDuration: (state, action: PayloadAction<any>) => {
			state.duration = action.payload
		},
		setTestData: (state, action: PayloadAction<any>) => {
			state.testData = action.payload
		},
		setTotalQuestions: (state, action: PayloadAction<any>) => {
			state.totalQuestion = action.payload
		},
		setSelectedQuestion: (state, action: PayloadAction<any>) => {			
			state.selectedQuestion = action.payload
		},
		setIsStartTest: (state, action: PayloadAction<boolean>) => {
			state.isStartTest = action.payload
		},
		setAllQuestionScore: (state, action: PayloadAction<any>) => {
			state.allQuestionScore = action.payload
		},
		changeSuccessSubmitTest: (state, action: PayloadAction<boolean>) => {
			state.successApplyPopup = action.payload;
		},
		changeSubmitTest: (state, action: PayloadAction<any>) => {
			state.applyPopup = action.payload;
		},
		setModal: (state, action: PayloadAction<any>) => {
			state.modal = action.payload;
		},
		setModalClose: (state, action: PayloadAction<any>) => {
			state.modalClose= action.payload;
		},
		setCandidateAnswer: (state, action: PayloadAction<any>) => {
			let data = JSON.parse(JSON.stringify(state.candidateAnswer));
			const answerData = action.payload;	
					
			data = data.filter((item) => item?.questionId !== "" && item?.questionId !== null);
			if (answerData) {
			  const index = data.findIndex((d) => d?.questionId === answerData?.questionId);
			  if (index !== -1) {
				data.splice(index, 1);
			  }
			  data.push(answerData);
			  state.candidateAnswer = data;
			}
		  },
	},
})


export const {
	setCandidateAnswer,
	setAssessmentId,
	setDuration,
	setJobId,
	setTotalScore,
	setTestData,
	setSelectedQuestion,
	setTotalQuestions,
	setIsStartTest,
	changeSuccessSubmitTest,
	changeSubmitTest,
	setModal,
	setModalClose
} = domainAssessment.actions

export default domainAssessment.reducer