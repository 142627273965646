import { getEnv } from "@urecruits/api";
import { store, useTypedSelector } from "../store"
import { IColumns } from "@ucrecruits/globalstyle/dist/types/table-types";
import { Link, useNavigate } from "react-router-dom";
import { setCurrentPageCandidateOffers, setLimitCandidateOffers, setSearchValueCandidateOffers, setTotalCountCandidateOffers } from "../store/reducers/candidateOffersReducer";
import defaultAvatar from "../image/temp-company.png";
import ViewSVG from "../components/SVG/ViewSVG";
import AcceptSVG from "../components/SVG/AcceptSVG";
import RejectSVG from "../components/SVG/RejectSVG";

import { useCallback, useState } from "react";
import axios from "axios"
import { TableCardViewComp } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const { API_RECRUITMENT } = getEnv();
const token: string = localStorage.getItem("token");
const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
};

const getTableData = store => store.candidate_offers;

const isCandidateStatus = (row: any, status: string | string[]) => {
  const candidateStatus = row.offer.candidateStatus
  if (typeof status === 'string') {
    return candidateStatus === status
  }
  else if (typeof status === 'object' && Array.isArray(status)) {
    return status.includes(candidateStatus)
  }
  return
}

const CandidateOffers = () => {
  const offerTable = useTypedSelector(getTableData);

  const navigate = useNavigate()
  const columns: Array<IColumns> = [
    {
      field: 'jobTitle',
      headerName: "Job Title",
      isMobileTitle: true,
      pinnable: true,
      visibility: true,
      renderCell: (cellValue, rowData) => {
        return (<p className="candidate-table__link">{rowData.offer.jobTitle || ''}</p>)
      },
      mobileRenderCell: (cellValue, rowData) => {
        return (<p className="candidate-table__link">{rowData.offer.jobTitle || ''}</p>)
      },

    },
    {
      headerName: 'Company',
      field: 'company',
      renderCell: (cellValue, rowData) => {
        const company = rowData.offer.company

        return (<div className="candidate-table__company">
          <img
            src={!!company.avatar ? company.avatar : defaultAvatar} alt="avatar"
            className="candidate-table__company__avatar" />
          <p className="candidate-table__company__text">
            {company.name}
          </p>
        </div>
        )
      },
      mobileRenderCell: (cellValue, rowData) => {
        const company = rowData.offer.company
        return (<div className="candidate-table__company">
          <img
            src={!!company.avatar ? company.avatar : defaultAvatar} alt="avatar"
            className="candidate-table__company__avatar" />
          <p className="candidate-table__company__text">
            {company.name}
          </p>
        </div>
        )
      },
      visibility: true,
    },
    {
      headerName: 'Total Scores',
      field: 'totalScores',
      visibility: true,
      renderCell: (cellValue, rowData) => {
        return `${cellValue}%`
      }
    },
    {
      headerName: 'Actions',
      field: 'actions',
      visibility: true,
      setActions: [
        {
          getAction: (row) => {
            if (isCandidateStatus(row, "rejected")) {
              return
            }
            return {
              svg: <ViewSVG />,
              title: 'View',
              onClick: (row, event) => {
                navigate(`/candidate/offers/view/${row.offer.id}`)
              },
              className: ''
            }
          }

        },
        {
          getAction: (row) => {
            if (isCandidateStatus(row, ["accepted", "rejected"])) {
              return
            }
            return {
              svg: <AcceptSVG />,
              title: 'Accept',
              onClick: (row, event) => {
                navigate(`/candidate/offers/view/${row.offer.id}?type=accept`)
              },
              className: ''
            }
          }
        },
        {
          getAction: (row) => {
            if (isCandidateStatus(row, ["accepted", "rejected"])) {
              return
            }
            return {
              svg: <RejectSVG />,
              title: 'Reject',
              onClick: (row, event) => {
                navigate(`/candidate/offers/view/${row.offer.id}?type=reject`)
              },
              className: ''
            }
          }

        }
      ]

    }
  ]

  const setSearchCallback = useCallback((value) => {
    store.dispatch(setSearchValueCandidateOffers(value));
    setCurrentPage(1);
  }, []);

  const createAPIString = () => {
    const pagination = offerTable.pagination
    return `${API_RECRUITMENT}/api/candidate-offers?limit=${pagination.limit}&offset=${(pagination.currentPage - 1) * pagination.limit}${offerTable.searchValue ? '&searchValue=' + offerTable.searchValue : ""}`
  }

  const setLimitHandler = (value: any) => {
    store.dispatch(setLimitCandidateOffers(value));
  };

  const setCurrentPage = (page: number) => {

    store.dispatch(setCurrentPageCandidateOffers(page));
  };

  return (
    <>
      <section className="candidate-offers">
        <div className="candidate-offers__head">
          <div className="candidate-offers__head__left">
            <h2 className="candidate-offers__head__headline">
              Offers
            </h2>
          </div>
        </div>
        <TableCardViewComp
          columns={columns}
          listAPI={createAPIString()}
          store={store}
          disableRow={{
            text: "Offer Rejected", getDisableStatus: (rowData) => {
              if (rowData.offer.candidateStatus === "rejected") return true;
              return false
            }
          }}
          storeName='candidate_offers'
          useTypedSelector={useTypedSelector}
          emptyTable={{
            title: 'No Offers',
          }}
          pagination={{
            currentPage: offerTable.pagination.currentPage,
            limit: offerTable.pagination.limit,
            totalCount: offerTable.pagination.totalCount,
            setCurrentPage: setCurrentPageCandidateOffers,
            setLimit: setLimitHandler,
            setTotalCount: (count: number) => { store.dispatch(setTotalCountCandidateOffers(count)) },
          }}
          searchField={{
            placeholder: 'Search assessment',
            searchValue: offerTable.searchValue,
            setSearchValue: setSearchCallback
          }}
        />
      </section>
    </>
  )
}

export default CandidateOffers

