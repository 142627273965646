import {memo, useEffect, useState} from "react";
import MultiSelectListItem from "./MultiSelectListItem";
import axios from "axios";
import {getEnv} from "@urecruits/api";
import {store, useTypedSelector} from "../../../store";
import {setSkillsFilterCompanyJobs} from "../../../store/reducers/companyJobsReducer";

const filterSkillFunc = state => state.jobs.filters.skills;

const MultiSelect = ({defaultSkills}) => {
	const {API_RECRUITMENT} = getEnv();
	const [inputValue, setInputValue] = useState("");
	const [skillList, setSkillList] = useState(defaultSkills);

	const selectedSkills = useTypedSelector(filterSkillFunc);

	useEffect(() => {
		const func = async () => {
			try {
				await axios.get(`${API_RECRUITMENT}/api/skills/${inputValue}`).then(res => setSkillList(res.data));
			} catch (e) {
				console.error("Fetch error", e);
			}
		};
		const timer = setTimeout(() => {
			func().then();
		}, 500);

		return () => clearTimeout(timer);
	}, [inputValue]);


	return (
		<div className="multi-select">
			<div className="multi-select__head">
				{
					selectedSkills.map((item, index) => {
						return (
							<div className="multi-select__head__item" key={index + item}>
								<p className="multi-select__head__text">
									{item}
								</p>
								<svg
									className="multi-select__head__close"
									onClick={() => store.dispatch(setSkillsFilterCompanyJobs(selectedSkills.filter(x => x !== item)))}
									width="16"
									height="16"
									viewBox="0 0 16 16"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M12 4L8 8M4 12L8 8M8 8L4 4L12 12"
										stroke="#015462"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</div>
						);
					})
				}
				<input
					type="text"
					className="multi-select__head__input"
					value={inputValue}
					onChange={e => setInputValue(e.target.value)}
					placeholder={selectedSkills?.length === 0 ? "Enter skill(s)" : ""}
				/>
			</div>
			<div className="multi-select__list">
				<div className="multi-select__list__inner">
					{
						skillList?.map((item, index) => {
							return (
								<MultiSelectListItem
									key={index + item.name}
									item={item}
									setInputValue={setInputValue}
								/>
							);
						})
					}
				</div>
			</div>
		</div>
	);
};

export default memo(MultiSelect);