export default () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.9998 18.3334H4.99967C4.55765 18.3334 4.13372 18.1578 3.82116 17.8453C3.5086 17.5327 3.33301 17.1088 3.33301 16.6667V3.33341C3.33301 2.89139 3.5086 2.46746 3.82116 2.1549C4.13372 1.84234 4.55765 1.66675 4.99967 1.66675H11.6663L16.6663 6.66675V11.475" stroke="#099C73" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M11.667 1.66675V6.66675H16.667" stroke="#099C73" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M8.33366 7.5H7.50033H6.66699" stroke="#099C73" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M5.16602 13.8333C5.16602 13.8333 7.52965 9.33325 11.666 9.33325C15.8024 9.33325 18.166 13.8333 18.166 13.8333C18.166 13.8333 15.8024 18.3333 11.666 18.3333C7.52965 18.3333 5.16602 13.8333 5.16602 13.8333Z" stroke="#099C73" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M11.666 15.3333C12.4944 15.3333 13.166 14.6617 13.166 13.8333C13.166 13.0048 12.4944 12.3333 11.666 12.3333C10.8376 12.3333 10.166 13.0048 10.166 13.8333C10.166 14.6617 10.8376 15.3333 11.666 15.3333Z" stroke="#099C73" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

  )
}