import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ICompanyJobs} from "../../types/redux/companyJobs";
import {IAsyncCheckBoxList, ITabsFilter} from "../../types/global/global";
import {CompanyJobsFilters, CompanyJobsTableTabsEnums} from "../../enums/CompanyJobsTableEnums";

export const defaultFiltersCompanyJobs = {
	searchValue: '',
	sortBy: 'id',
	sortType: 'ASC',
	locations: [],
	experience: [0,50],
	salaryMonth: [0,20000],
	salaryYear: [0,1000000],
	skills: [],
	postedOn: '',
	tenantId: '',
}

const initialStateCompanyJobs: ICompanyJobs = {
	tableView: 'table',
	emptySearch: false,
	emptyTable: false,
	filters: defaultFiltersCompanyJobs,
	tableItems: [{
		id:1
	}],
	companyJobs: [],
	fixedTab: {
		id: 1,
		displayName: CompanyJobsTableTabsEnums.JOB_TITLE,
	},
	jobTabFilter: [
		{
			id: 2,
			displayName: CompanyJobsTableTabsEnums.JOB_LOCATION,
			active: true,
			dbName: ''
		},
		{
			id: 3,
			displayName: CompanyJobsTableTabsEnums.EXPERIENCE,
			active: true,
			dbName: 'experienceMin'
		},
		{
			id: 4,
			displayName: CompanyJobsTableTabsEnums.SALARY_RANGE,
			active: true,
			dbName: 'salaryYearMin'
		},
		{
			id: 5,
			displayName: CompanyJobsTableTabsEnums.MAIN_SKILLS,
			active: true,
			dbName: 'skills'
		},
		{
			id: 6,
			displayName: CompanyJobsTableTabsEnums.POSTED_ON,
			active: true,
			dbName: 'createdAt'
		},
		{
			id: 7,
			displayName: CompanyJobsTableTabsEnums.ACTIONS,
			active: true,
			dbName: ''
		},
	],
	pagination: {
		currentPage: 1,
		limit: 10,
		totalCount: 0
	},
	filterInfo: {
		tabs:
			[
				CompanyJobsFilters.Location,
				CompanyJobsFilters.Experience,
				CompanyJobsFilters.Salary,
				CompanyJobsFilters.Skills,
				CompanyJobsFilters.Posted,
			],
	},
	applyPopup: {
		visible: false,
		jobId: 0,
		jobTitle: "",
	},
	successApplyPopup: false,
}

export const companyJobs = createSlice({
	name: 'company_jobs',
	initialState: initialStateCompanyJobs,
	reducers: {
		setJobTableView: (state, action: PayloadAction<'table'|'list'>) => {
			state.tableView = action.payload
		},
		setTenantId: (state, action: PayloadAction<string>) => {
			state.filters.tenantId = action.payload
		},
		setCompanyJobs: (state, action: PayloadAction<any>) => {
			state.companyJobs = action.payload
		},
		setLocationFilterCompanyJobs: (state, action: PayloadAction<Array<IAsyncCheckBoxList>>) => {
			state.filters.locations = action.payload
		},
		setExperienceFilterCompanyJobs: (state, action: PayloadAction<Array<number>>) => {
			state.filters.experience = action.payload
		},
		setSalaryMonthFilterCompanyJobs: (state, action: PayloadAction<Array<number>>) => {
			state.filters.salaryMonth = action.payload
		},
		setSalaryYearFilterCompanyJobs: (state, action: PayloadAction<Array<number>>) => {
			state.filters.salaryYear = action.payload
		},
		setSkillsFilterCompanyJobs: (state, action: PayloadAction<Array<string>>) => {
			state.filters.skills = action.payload
		},
		setPostedOnFilterCompanyJobs: (state, action: PayloadAction<string>) => {
			state.filters.postedOn = action.payload
		},
		setEmptySearchCompanyJobs: (state, action: PayloadAction<boolean>) => {
			state.emptySearch = action.payload
		},
		setSearchValueCompanyJobs: (state, action: PayloadAction<string>) => {
			state.filters.searchValue = action.payload
		},
		setSortByCompanyJobs: (state, action: PayloadAction<string>) => {
			state.filters.sortBy = action.payload
		},
		setSortTypeCompanyJobs: (state, action: PayloadAction<string>) => {
			state.filters.sortType = action.payload
		},
		setOrderCompanyJobs: (state, action: PayloadAction<Array<ITabsFilter>>) => {
			state.jobTabFilter = action.payload
		},
		setTotalCountCompanyJobs: (state, action: PayloadAction<number>) => {
			state.pagination.totalCount = action.payload;
		},
		setLimitCompanyJobs: (state, action: PayloadAction<number>) => {
			state.pagination.limit = action.payload;
		},
		setCurrentPageCompanyJobs: (state, action: PayloadAction<number>) => {
			state.pagination.currentPage = action.payload;
		},
		changeSuccessApplyPopupJobs: (state, action: PayloadAction<boolean>) => {
			state.successApplyPopup = action.payload;
		},
		changeApplyPopupJobs: (state, action: PayloadAction<any>) => {
			state.applyPopup = action.payload;
		},
	},
})


export const {
	setCompanyJobs,
	setPostedOnFilterCompanyJobs,
	setSkillsFilterCompanyJobs,
	setSalaryYearFilterCompanyJobs,
	setExperienceFilterCompanyJobs,
	setLocationFilterCompanyJobs,
	setLimitCompanyJobs,
	setTotalCountCompanyJobs,
	setSortTypeCompanyJobs,
	setSortByCompanyJobs,
	setEmptySearchCompanyJobs,
	setSearchValueCompanyJobs,
	setSalaryMonthFilterCompanyJobs,
	setTenantId,
	setJobTableView,
	changeApplyPopupJobs,
	changeSuccessApplyPopupJobs,
} = companyJobs.actions

export default companyJobs.reducer