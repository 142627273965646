import {
        CustomRangeComponent,
} from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import { getEnv } from "@urecruits/api";
import axios from "axios";
import { memo, useEffect, useState } from "react";
import DatePicker from 'react-datepicker';
import { CompanyJobsFilters } from '../../../enums/CompanyJobsTableEnums';
import { store, useTypedSelector } from "../../../store";
import {
        setExperienceFilterCompanyJobs,
        setPostedOnFilterCompanyJobs,
        setSalaryMonthFilterCompanyJobs,
        setSalaryYearFilterCompanyJobs
} from "../../../store/reducers/companyJobsReducer";
import MultiSelect from "./../MultySelect/MultiSelect";
import { LocationFilter } from "./components";

const getFiltersFunc = (state) => state.jobs.filters;

const CompanyJobsFilter = ({ activeTab }) => {
        const filter = useTypedSelector(getFiltersFunc)
        const [defaultSkills, setDefaultSkills] = useState([]);
        const { API_RECRUITMENT } = getEnv()

        useEffect(() => {
                const func = async () => {
                        try {
                                await axios.get(`${API_RECRUITMENT}/api/skills/`).then(res => setDefaultSkills(res.data))
                        } catch (e) {
                                console.error('Fetch error', e)
                        }
                }
                func().then()
        }, []);

        return <>
                {
                        activeTab === CompanyJobsFilters.Location &&
                        <div className='table-filter-popup__right__content'>
                                <LocationFilter />
                        </div>
                }
                {
                        activeTab === CompanyJobsFilters.Experience && <div className='table-filter-popup__right__content'>
                                <label className='table__range__label'>Experience<span>(years)</span></label>
                                <CustomRangeComponent rangeValue={filter.experience} max={50} min={0} setData={(state) => {
                                        store.dispatch(setExperienceFilterCompanyJobs(state))
                                }} />
                        </div>
                }
                {
                        activeTab === CompanyJobsFilters.Salary && <div className='table-filter-popup__right__content'>
                                <label className='table__range__label'>Salary Range<span>(per month)</span></label>
                                <CustomRangeComponent rangeValue={filter.salaryMonth} max={20000} min={0} dollar={true} setData={(state) => {
                                        store.dispatch(setSalaryMonthFilterCompanyJobs(state))
                                }} />
                                <label className='table__range__label mt'>Salary Range<span>(per year)</span></label>
                                <CustomRangeComponent rangeValue={filter.salaryYear} max={1000000} min={0} dollar={true} setData={(state) => {
                                        store.dispatch(setSalaryYearFilterCompanyJobs(state))
                                }} />
                        </div>
                }
                {
                        activeTab === CompanyJobsFilters.Skills && <MultiSelect defaultSkills={defaultSkills} />
                }
                {
                        activeTab === CompanyJobsFilters.Posted &&
                        <div className='table-filter-popup__right__content'>
                                <div className='filters-popup-mtm-datepicker'>
                                        <div className='filters-popup-mtm-datepicker__item'>
                                                <label className="filters-popup-mtm-datepicker__label">Chose date</label>
                                                <div className='filters-popup-mtm-datepicker__date'>
                                                        <DatePicker
                                                                selected={filter.postedOn}
                                                                showYearDropdown={true}
                                                                scrollableYearDropdown={true}
                                                                yearDropdownItemNumber={70}
                                                                onChange={(date: any) => {
                                                                        store.dispatch(setPostedOnFilterCompanyJobs(date))
                                                                }}
                                                                dateFormat="MMMM d, yyyy"
                                                                maxDate={new Date()}
                                                                placeholderText="Date"
                                                                id={'datePicker'}
                                                        />
                                                </div>
                                        </div>
                                </div>
                        </div>
                }
        </>
}
export default memo(CompanyJobsFilter)
