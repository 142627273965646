import { useDispatch, useSelector } from "react-redux";
import { navigate } from "@reach/router";
import * as types from "../../../types/redux/domainAssessment";
import { useMemo, useState } from "react";
import textData from "./editor-modals-text.json";
import {store, useTypedSelector} from "../../../store";
import React from "react";
import { setModal } from "../../../store/reducers/domainAssessmentReducer";
import Modal from "./Modal";

const congrImage = require("../../../../assessment/live-coding/assets/icons/congrad.svg");

export const EditorModals = () => {
  const dispatch = useDispatch();
  const {modal,totalScore} = useTypedSelector(state => state.domain_assessment);

  const renderContent = useMemo(() => {
    const data = textData[modal];

    switch (modal) {
      case types.CodingModalsEnum.deadline:
        return {
          title: data.deadlineIsOver,
          body: (
            <>
              <p className="EditorModals__bold">{data.dearCandidate}</p>
              <p className="EditorModals__text">
                {data.assessmentTimeLimitWillBeExpired}
              </p>
              <p className="EditorModals__bold">{data.regards}</p>
              <p className="EditorModals__bold">{data.uRecruitsTeam}</p>
              <button
                className="c-btn c-btn-big c-btn--green"
                onClick={() => {
                  dispatch(setModal(null));
                  navigate("/")}
                }
              >
                {data.contactRecruitmentTeam}
              </button>
            </>
          ),
        };
      case types.CodingModalsEnum.inProgress:
        return {
          title: data.deadlineIsOver,
          body: (
            <>
              <p className="EditorModals__bold">{data.dearCandidate}</p>
              <p className="EditorModals__text">
                {data.assessmentTimeLimitWillBeExpired}
              </p>
              <p className="EditorModals__text">
                {data.message}
              </p>
              <p className="EditorModals__bold">{data.regards}</p>
              <p className="EditorModals__bold">{data.uRecruitsTeam}</p>
              <button
                className="c-btn c-btn-big c-btn--green"
                onClick={() => {
                  dispatch(setModal(null));
                  navigate("/")}
                }
              >
                {data.contactRecruitmentTeam}
              </button>
            </>
          ),
        };

      case types.CodingModalsEnum.congratulations:
        return {
          title: data.congratulations,
          body: (
            <>
              <img
                className="EditorModals__image"
                src={congrImage}
                alt="congratulations"
              />
              <p className="EditorModals__text">
                {data.youHaveSuccessfullyCompletedAssignment}
              </p>
              <button
                className="c-btn c-btn-big c-btn--green"
                onClick={() => {
                  dispatch(setModal(null));
                  navigate("/");
                }}
              >
                {data.gotIt}
              </button>
            </>
          ),
        };
      case types.CodingModalsEnum.completed:
        return {
          title: data.completed,
          body: (
            <>
              <img
                className="EditorModals__image"
                src={congrImage}
                alt="completed"
              />
              <p className="EditorModals__text">
                {data.youHaveSuccessfullyCompleted}
                {totalScore && ` You got ${totalScore} marks in this assessment`}
              </p>
              <button
                className="c-btn c-btn-big c-btn--green"
                onClick={() => {
                  dispatch(setModal(null));
                  navigate("/");
                }}
              >
                {data.gotIt}
              </button>
            </>
          ),
        };
      default:
        return {
          title: null,
          body: <></>,
        };
    }
  }, [modal]);

  return (
    <div className="EditorModals">
      <Modal isOpen={modal} title={renderContent.title}>
        <div className={`Modal__body ${modal}`}>{renderContent.body}</div>
      </Modal>
    </div>
  );
};
