import {memo, useLayoutEffect, useState} from "react";
import {Link, Outlet, useLocation, useParams} from "react-router-dom";
import defaultAvatar from "../image/icon/avatar.svg";
import {ICompany} from "../screen/AboutCompany";

import {getEnv} from "@urecruits/api";
import fetchData from "../hook/fetchData";
import {store} from "../store";
import {setTenantId} from "../store/reducers/companyJobsReducer";

const {API_RECRUITMENT} = getEnv();

const LayoutCompany = () => {
	const location = useLocation();
	const params = useParams();
	const [company, setCompany] = useState<ICompany>();

	//TODO: change to redux
	useLayoutEffect(() => {
		fetchData(`${API_RECRUITMENT}/api/company/public/${params.tenantId}`).then(res => setCompany(res));
		store.dispatch(setTenantId(params.tenantId));
	}, []);

	return (
		<section className="company-jobs">
			<div className="jobs-head">
				<div className="jobs-head__inner">
					<ul className="breadcrumbs">
						<li className="breadcrumbs__item">
							<Link to="/candidate/companies" className="breadcrumbs__item__link">
								Companies
							</Link>
						</li>
						<li className="breadcrumbs__item">
							<Link to={`/candidate/company/${params.tenantId}`} className="breadcrumbs__item__link">
								{company?.name}
							</Link>
						</li>
						{
							location.pathname.includes("/opened-jobs") && (
								<li className="breadcrumbs__item">
									<Link to={`/candidate/company/${params.tenantId}/opened-jobs`} className="breadcrumbs__item__link">
										Opened Jobs
									</Link>
								</li>
							)
						}
					</ul>
					<div className="jobs-head__body">
						<div className="jobs-head__left">
							<div className="jobs-head__logo">
								<img src={company?.avatar || defaultAvatar} alt={company?.name} className="jobs-head__logo__img"/>
							</div>
						</div>
						<div className="jobs-head__right">
							<p className="jobs-head__name">
								{company?.name}
							</p>
							<ul className="jobs-head__info">
								{
									company?.addresses && (
										<li className="jobs-head__info__item location">

										<span>
											{`${company?.addresses[0].country || ""}${company?.addresses[0].location ? "," : ""} ${company?.addresses[0].location || ""}`}
										</span>
										</li>
									)
								}
								{/*//TODO: need no change link and text*/}
								<li className="jobs-head__info__item">
									{company?.website && <a href={`${company.website}`} target={"_blank"} className="jobs-head__info__link">
										<LinkSVG/>
										{company.website}
									</a>}
								</li>
							</ul>
							<div className="jobs-head__buttons">
								<Link
									to={`/candidate/company/${params.tenantId}`}
									className={` jobs-head__buttons__item ${!location.pathname.includes("/opened-jobs") ? "active" : ""}`}
								>
									About company
								</Link>
								<Link
									to={`/candidate/company/${params.tenantId}/opened-jobs`}
									className={` jobs-head__buttons__item ${location.pathname.includes("/opened-jobs") ? "active" : ""}`}
								>
									Opened jobs
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Outlet/>
		</section>
	);
};
export default memo(LayoutCompany);

const LinkSVG = () => {
	return (
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M12 8.66667V12.6667C12 13.0203 11.8595 13.3594 11.6095 13.6095C11.3594 13.8595 11.0203 14 10.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V5.33333C2 4.97971 2.14048 4.64057 2.39052 4.39052C2.64057 4.14048 2.97971 4 3.33333 4H7.33333"
				stroke="#DFE2E6" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M10.0003 2H14.0003M14.0003 2V6M14.0003 2L6.66699 9.33333" stroke="white" strokeWidth="1.5" strokeLinecap="round"
			      strokeLinejoin="round"/>
		</svg>
	);
};