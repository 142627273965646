import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import fetchData from "../../hook/fetchData";
import { decodeToken, getEnv } from "@urecruits/api";
import { Stack, Skeleton } from '@mui/material';
import { store } from "../../store";
import { setFilterTypeCandidateJobs } from "../../store/reducers/candidateJobsReducer";
import { DashboardEmptyDataComponent, DashboardListArrowSVG } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import SingleMobileItem from "@ucrecruits/globalstyle/components/Table/MobileTable/SingleMobileItem";
const { API_RECRUITMENT } = getEnv()

const columns = [
	{ label: "Job Title", className: "" },
	{ label: "Employer", className: "" },
	{ label: "Salary Range", className: "" },
	{ label: "Status", className: "" },
]
const AppliedJobsTable = ({styles}) => {
	const [appliedJobs, setAppliedJobs] = useState([])
	const [loading, setLoading] = useState(true)
	const [userId, setUserId] = useState()
	const [isEmptyData, setIsEmptyData] = useState(false)
	const [screenWidth, setScreenWidth] = useState(window.innerWidth);

	useEffect(() => {
		const updateScreenSize = () => {
			setScreenWidth(window.innerWidth);
		};
		addEventListener("resize", updateScreenSize);
		return () => {
			removeEventListener("resize", updateScreenSize);
		};
	}, []);

	useEffect(()=>{
	const getUserId = async () => await decodeToken().then(data => data["https://urecruits.com/userId"]);
	getUserId().then(id => setUserId(id))
	},[])
	useEffect(() => {
		if(userId){
			setLoading(true)
			fetchData(`${API_RECRUITMENT}/api/job?limit=5&offset=0&filterType=applied&currentUserId=${userId}`).then(res => {
				if(res.rows && res.rows.length){
					setAppliedJobs(res.rows);
				}else{
					setIsEmptyData(true)
				}
					setLoading(false);
			}).catch((e) => {
				console.log("error Getting applied Jobs", e)
				setLoading(false)
				setIsEmptyData(true)
			})
		}
	}, [userId])

	return (
		<div className="dashboard-table" style={styles}>
			<div className="dashboard-table__header">
				<p className="dashboard-table__header__title">
					Applied Jobs
				</p>
				<Link to={"/candidate/jobs"} className="dashboard-table__header__link" onClick={() => store.dispatch(setFilterTypeCandidateJobs("applied"))}>View all jobs</Link>
			</div>
			{
				loading && <Stack height={"100%"} padding={"12px"}>
					<Skeleton animation="wave" />
				</Stack>
			}{
				isEmptyData && <DashboardEmptyDataComponent />
			}

			{
				!isEmptyData && !loading && appliedJobs.length > 0 &&
				(
					screenWidth <= 786 ? <>
						<div className="dashboard-table__table">
							<div className="dashboard-table__tbody">
								{appliedJobs.map(item => <MobileItem item={item} key={item.id} />)}
							</div>
						</div>
					</> : screenWidth > 786 &&
					<table className="dashboard-table__table">
						<thead className="dashboard-table__thead">
							{
								columns.map((col, index) => {
									return <td className={`dashboard-table__thead__td ${col.className || ""}`} key={index}>
										{col.label}
									</td>
								})
							}
						</thead>
						<tbody className="dashboard-table__tbody">
							{appliedJobs.map(job => {
								if (job) {
									return <tr className="dashboard-table__tbody__tr" key={job.id}>
										<td className="dashboard-table__tbody__td">
											<Link to={`/job/${job.id}`} className="dashboard-table__link">
												{job.title || ""}
											</Link>
										</td>
										<td className="dashboard-table__tbody__td green">
											{job.employer || ""}
										</td>
										{<td className="dashboard-table__tbody__td gray">
											{`${job.salaryYearMin || ''}$ - ${job.salaryYearMax || ''}$ PA`}
										</td>}
										<td className="dashboard-table__tbody__td">
											{job.status ?
												<div className={`dashboard-table__status ${job.status === "publish" ? "publish" : job.status === "closed" ? "not-publish" : ""}`}>
													{job?.status === "publish" ? "Active" : job.status === "closed" ? "Non-Active" : ""}
												</div> : ''}
										</td>
									</tr>
								} else {
									return;
								}
							})
							}
						</tbody>
					</table>
				)
			}
		</div>
	);
};

export default AppliedJobsTable;

const MobileItem = ({ item }) => {
	const [openItem, setOpenItem] = useState(false)


	return <div className="table-mobile__item dashboard-table__mobile__item">
		<div
			className={`table-mobile__top ${openItem ? "active" : ""}`}
			onClick={() => {
				setOpenItem((prev) => !prev);
			}}>
			<div className="table-mobile__top__left">
				<p className="table-mobile__top__name dashboard-table__mobile__text">
					<Link to={`/job/${item.id}`} className="dashboard-table__link">
						{item?.title || ""}
					</Link>
				</p>
			</div>
			<DashboardListArrowSVG />
		</div>
		<div className={`table-mobile__item__body ${openItem ? "open" : ""}`}>
			<SingleMobileItem
				title={columns[0].label}
				content={<Link to={`/job/${item.id}`} className="table-mobile__item__text green">{item.title || ""}</Link>}
				textStyle="table-mobile__item__text"
			/>
			<SingleMobileItem title={columns[1].label} content={`${item.employer || ""}`} textStyle="table-mobile__item__text" />
			<SingleMobileItem
				title={columns[2].label}
				content={`${item.salaryYearMin || ''}$ - ${item.salaryYearMax || ''}$ PA`}
				textStyle="table-mobile__item__text"
			/>
			<SingleMobileItem
				title={columns[3].label}
				content={<div className="dashboard-table__status publish">
					{item?.status}
				</div>}
				textStyle="table-mobile__item__text"
			/>

		</div>
	</div>
}